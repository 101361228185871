import React, { useState } from 'react';
import './ThirdTabCard.css';
import heart from "../../assets/images/heart-svgrepo-com.svg";
import {Modal} from "react-bootstrap";
import API_BASE_URL from "../../Constants";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

const ThirdTabCard = ({discount,title,product_id,discounted_price,sizes,price,images}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [activeImg, setActiveImg] = useState(images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : null);
    const [currentImage, setCurrentImage] = useState(images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : null);
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState('Small');
    const [showModal, setShowModal] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleRoundImageHover = (image) => {
        setCurrentImage(image);
    };

    const handleRoundImageClick = (image) => {
        setCurrentImage(image);
        setIsHovered(true);
    };

    const handleAddToCart = () => {
        const product = {
            title,
            discounted_price,
            product_id,
            price,
            images,
            quantity,
            selectedSize,
            discount,
        };

        let cart = JSON.parse(sessionStorage.getItem('cart')) || [];

        const productIndex = cart.findIndex((item) => item.product_id === product_id);

        if (productIndex !== -1) {
            cart[productIndex].quantity += quantity;
        } else {
            cart.push(product);
        }

        sessionStorage.setItem('cart', JSON.stringify(cart));
        console.log('Product added/updated in cart:', product);

        setShowModal(false);


        Swal.fire({
            icon: 'success',
            title: 'Added to Cart',
            text: `${product.title} has been added to your cart!`,
            showConfirmButton: false,
            timer: 2000,
        });

    };


    const handleQuickShopClick = () => {
        setShowModal(true);
    };
    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };
    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };
    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleRoundImageClick1 = (image) => {
        setActiveImg(image);
        setIsHovered(true);
    };
    return (
        <div className="third-tab-card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`third-image-container1 ${isHovered ? 'zoomed' : ''}`}>
                {isHovered ? (
                    <img
                        src={currentImage}
                        alt="Zoomed"
                        className="third-zoomed-image"
                    />
                ) : (
                    <img
                        src={`${API_BASE_URL}${images[1].file_location}` ?? ""}
                        alt="Original"
                        className="third-original-image"
                    />
                )}

            </div>
            <div className={"discount-container"}>-{discount}%</div>
            <div className={"new-container"}>New</div>
            <div className={"icons-zoom-container1"}>
                <a className="tooltip-container" aria-label="Add to Favorites">
                    <img src={heart} className={"icons-zoom-img"} alt="Heart Icon"/>
                    <span className="tooltip-text">Add to Wishlist</span>
                </a>
                <a className="tooltip-container" aria-label="Add to Favorites">
                    <img src={heart} className={"icons-zoom-img"} alt="Heart Icon"/>
                    <span className="tooltip-text">Add to Favorites</span>
                </a>
            </div>
            {isHovered ? (
                <div className={"third-hover-text1"}>
                    <span>{sizes}</span>
                    <button className={"quick-shop-btn"} onClick={handleQuickShopClick}>QuickShop</button>
                </div>
            ) : (
                ""
            )}

            <div className="content-card">
                <p className={"suit-title"}>{title}</p>
                <p className={"price-container-card"}>
                    <span className={"price"}>{discounted_price}</span>
                    <span className={"discounted-price"}>{price}</span>
                </p>
                <div className={"img-container"}>
                    {images.map((image_new, index) => {
                        return (
                            <img
                                key={index}
                                src={`${API_BASE_URL}${image_new.file_location}`}
                                alt="Zoomed"
                                className="img-round"
                                onMouseEnter={() => handleRoundImageHover(`${API_BASE_URL}${image_new.file_location}`)}
                                onClick={() => handleRoundImageClick(`${API_BASE_URL}${image_new.file_location}`)}
                            />
                        );
                    })}
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className={"detail-modal"}>
                        <div className={"detail-modal-top"}>
                            <img src={activeImg} alt={"not found"} />
                            <div className={"detail-modal-right"}>
                                <span className={"title"}>{title}</span>
                                <div className={"price-discount"}>
                                    <span className={"cancel-price"}>{discounted_price}</span>
                                    <span className={"price"}>{price}</span>
                                    <span className={"discount-box"}>{discount}%</span>
                                </div>
                            </div>
                        </div>
                        <div className={"colo-box"}>COLOR: BLACK</div>
                        <div className={"img-div"}>
                            <img className={"roud-img"} onClick={() => handleRoundImageClick1(images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : '')} src={images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : ''} alt={"not found"} />
                            <img className={"roud-img"} onClick={() => handleRoundImageClick1(images && images.length > 1 ? `${API_BASE_URL}${images[1].file_location}` : '')} src={images && images.length > 1 ? `${API_BASE_URL}${images[1].file_location}` : ''} alt={"not found"} />
                        </div>
                        <div className={"colo-box"}>SIZE: {selectedSize}</div>
                        <div className={"options-box"}>
                            {sizes && sizes.length > 0 ? (
                                sizes.map((size, index) => (
                                    <div
                                        key={index}
                                        className={`single-option ${selectedSize === size ? 'active' : ''}`}
                                    >
                                        <input
                                            type="radio"
                                            checked={selectedSize === size}
                                            onChange={() => handleSizeChange(size)}
                                        />
                                        {size}
                                    </div>
                                ))
                            ) : (
                                <div style={{ textAlign: 'center' }}>No Size Available</div>
                            )}


                        </div>
                        <div className={"quantity-container"}>
                            <span onClick={decrementQuantity}>-</span>
                            {quantity}
                            <span onClick={incrementQuantity}>+</span>
                        </div>
                        <br />
                        <button className={"add-to-cart-btn"} onClick={handleAddToCart}>ADD TO CART</button>
                        <Link to={`/product/single/${product_id}`} className={"view-detail"}>View full details</Link>
                    </div>
                </Modal.Body>
                <Modal.Footer/>
            </Modal>
        </div>
    );
};

export default ThirdTabCard;
