import './ProductSingle.css';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ProductTopBar from "../../components/ProductTopBar/ProductTopBar";
import TopHeader from "../../components/TopHeader/TopHeader";
import ProductSingleDetail from "../../components/ProductSingleDetail/ProductSingleDetail";
import YouMightAlsoLikeIt from "../../components/YouMightAlsoLikeIt/YouMightAlsoLikeIt";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import API_BASE_URL from "../../Constants";
import axios from "axios";

const ProductSingle = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}api/single_product/${id}`, {
                    headers: {
                        client_id: "hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM",
                        client_secret: "lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J"
                    }
                });
                console.log(response.data.data);
                setProduct(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching product:", error);
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <TopHeader />
            <Navbar />
            <ProductTopBar product_name={product.name}/>
            <ProductSingleDetail product={product} />
            <YouMightAlsoLikeIt />
            <Footer />
        </>
    );
};

export default ProductSingle;
