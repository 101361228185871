import "./CallingHours.css";
import axios from "axios";
import API_BASE_URL from "../../Constants";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";
const CallingHours = ()=>{

    const dispatch = useDispatch();
    const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);
    const [contact,setContact] = useState('');

    useEffect(() => {
        dispatch(fetchWebsiteConfigures());
    }, []);

    useEffect(() => {
        if (website_configures) {
            setContact(website_configures.phone ?? '');
        }
    }, [website_configures]);

    return (
        <>
        <div className={"calling-container"}>
            <div className={"calling-card"}>
                <div className={"calling-heaing"}>CALLING HOURS</div>
                <div className={"calling-border"}/>
                <div className={"calling-text"}>Whole week: 10:30 AM – 5:30 PM</div>
                <div className={"calling-border"}/>
                <div className={"calling-description"}>Customer Care/ Helpline:- {contact}</div>
            </div>
        </div>
        </>
    )
}
export default CallingHours;