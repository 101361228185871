import React, {useEffect, useState} from "react";
import "./Home.css";
import TopHeader from "../../components/TopHeader/TopHeader";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import NewArrivals from "../../components/NewArrivals/NewArrivals";
import AllCollection from "../../components/AllCollection/AllCollection";
import FeaturedCollection from "../../components/FeaturedCollection/FeaturedCollection";
import OurProducts from "../../components/OurProducts/OurProducts";
import Instagram from "../../components/Instagram/Instagram";
import CallingHours from "../../components/CallingHours/CallingHours";
import Footer from "../../components/Footer/Footer";
import CartDiaries from "../../components/CartDiaries/CartDiaries";
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";
const Home = () => {

    const dispatch = useDispatch();
    const [video_url,setVideoUrl] = useState('');
    const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);

    useEffect(() => {
        dispatch(fetchWebsiteConfigures());
    }, []);

    useEffect(() => {
        if (website_configures) {
            setVideoUrl(website_configures.video_url ?? '');
        }
    }, [website_configures]);

  return (
    <>
      <TopHeader />
      <Navbar />
      <Hero />
      <NewArrivals />
      <AllCollection />
      <FeaturedCollection />
      <OurProducts />
      <Instagram />
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          title="xyz"
          src={video_url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <CartDiaries />
      <CallingHours />
      <Footer />
    </>
  );
};
export default Home;
