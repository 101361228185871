import "./FirstTabCad.css";
import React, { useState } from "react";
import { Carousel, CloseButton, Modal } from "react-bootstrap";
import API_BASE_URL from "../../Constants";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const FirstTabCard = ({
  discount,
  from_price,
  to_price,
  description,
  number,
  sizes,
  product_id,
  images,
  title,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState("Small");
  const [activeImg, setActiveImg] = useState(
    images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : null
  );
  const [currentImage, setCurrentImage] = useState(
    images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : null
  );
  const [showModal, setShowModal] = useState(false);
  const [showReadModal, setShowReadModal] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleQuickShopClick = () => {
    setShowModal(true);
  };
  const handleReadShopClick = () => {
    setShowReadModal(true);
  };
  const handleAddToCart = () => {
    const product = {
      title,
      discounted_price:from_price,
      product_id,
      price:to_price,
      images,
      quantity,
      selectedSize,
      discount,
    };

    let cart = JSON.parse(sessionStorage.getItem('cart')) || [];

    const productIndex = cart.findIndex((item) => item.product_id === product_id);

    if (productIndex !== -1) {
      cart[productIndex].quantity += quantity;
    } else {
      cart.push(product);
    }

    sessionStorage.setItem('cart', JSON.stringify(cart));
    console.log('Product added/updated in cart:', product);

    setShowModal(false);

    Swal.fire({
      icon: 'success',
      title: 'Added to Cart',
      text: `${product.title} has been added to your cart!`,
      showConfirmButton: false,
      timer: 2000,
    });

  };
  const handleRoundImageHover = (image) => {
    setCurrentImage(image);
  };
  const handleRoundImageClick = (image) => {
    setCurrentImage(image);
    setIsHovered(true);
  };
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseReadModal = () => {
    setShowReadModal(false);
  };
  const handleRoundImageClick1 = (image) => {
    setActiveImg(image);
    setIsHovered(true);
  };
  return (
    <>
      <div
        className={"first-tab-card"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`first-image-container1 ${isHovered ? "zoomed" : ""}`}>
          {isHovered ? (
            <img
              src={currentImage}
              alt="Zoomed"
              className="first-zoomed-image"
            />
          ) : (
            <img
              src={`${API_BASE_URL}${images[1].file_location}`}
              alt="Original"
              className="first-original-image"
            />
          )}

          {isHovered ? (
            <div className={"hover-text1-first"}>
              <span>{title}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={"discount-container-first"}>-{discount}%</div>
        <div className={"new-container-first"}>New</div>
        <div className={"first-tab-card-content"}>
          <p className={"content-first"}>{number}</p>
          <p className={"first-tab-price"}>
            {from_price} – {to_price}
          </p>
          <p className={"first-tab-descrition"}>{description}</p>
          <div className={"img-container"}>
            {images.map((image_new, index) => {
              return (
                <img
                  key={index}
                  src={`${API_BASE_URL}${image_new.file_location}`}
                  alt="Zoomed"
                  className="img-round"
                  onMouseEnter={() => handleRoundImageHover(`${API_BASE_URL}${image_new.file_location}`)}
                  onClick={() => handleRoundImageClick(`${API_BASE_URL}${image_new.file_location}`)}
                />
              );
            })}
          </div>
        </div>
        <div className={"firsst-ard-button-container"}>
          <button className={"quick-view-btn"} onClick={handleQuickShopClick}>
            Quick View
          </button>
          <Link to={`/product/single/${product_id}`} className={"read-more-btn"}>Read More</Link>
        </div>
        <Modal
          show={showReadModal}
          onHide={handleCloseReadModal}
          dialogClassName="custom-modal"
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <CloseButton />
            </div>
            <div className={"read-detail-modal"}>
              <div className={"read-modal-left"}>
                <Carousel
                  indicators={true}
                  controls={true}
                  interval={4000}
                  pause={true}
                >
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={`${API_BASE_URL}${images[0].file_location}`}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={`${API_BASE_URL}${images[1].file_location}`}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={`${API_BASE_URL}${images[0].file_location}`}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className={"read-modal-right"}>
                <div className={"modal-right-title"}>F-157</div>
                <div className={"pricing-section"}>
                  <div className={"prices"}>
                    <span>€29.34 </span>
                    <p>€21.72 </p>
                  </div>
                  <div className={"instock"}>In Stock</div>
                </div>
                <div className={"shipping-mdal-text"}>
                  Shipping calculated at checkout.
                </div>
                <div className={"color-text"}>
                  COLOR:SHOCKING PINK + TURQUOISE
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="custom-modal"
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end mb-2">
              <CloseButton onClick={handleCloseModal} />
            </div>
            <div className={"detail-modal"}>
              <div className={"detail-modal-top"}>
                <img src={activeImg} alt={"not found"} />
                <div className={"detail-modal-right"}>
                  <span className={"title"}>{title}</span>
                  <div className={"price-discount"}>
                    <span className={"cancel-price"}>{from_price}</span>
                    <span className={"price"}>{to_price}</span>
                    <span className={"discount-box"}>{discount}%</span>
                  </div>
                </div>
              </div>
              <div className={"colo-box"}>COLOR: BLACK</div>
              <div className={"img-div"}>
                <img
                  className={"roud-img"}
                  onClick={() =>
                    handleRoundImageClick1(
                      images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : ""
                    )
                  }
                  src={images && images.length > 0 ? `${API_BASE_URL}${images[0].file_location}` : ""}
                  alt={"not found"}
                />
                <img
                  className={"roud-img"}
                  onClick={() =>
                    handleRoundImageClick1(
                      images && images.length > 1 ? `${API_BASE_URL}${images[1].file_location}` : ""
                    )
                  }
                  src={images && images.length > 1 ? `${API_BASE_URL}${images[1].file_location}` : ""}
                  alt={"not found"}
                />
              </div>
              <div className={"colo-box"}>SIZE: {selectedSize}</div>
              <div className={"options-box"}>
                {
                  sizes && sizes.length > 0 ?(
                      sizes.map((index,size)=>{
                        return   <div
                            className={`single-option ${
                                selectedSize === size ? "active" : ""
                            }`}
                        >
                          <input
                              type={"radio"}
                              checked={selectedSize === size}
                              onChange={() => handleSizeChange(size)}
                          />
                          {size}
                        </div>
                      })
                  ) : (
                      <div style={{textAlign:"center"}}>No size Available</div>
                  )
                }


              </div>
              <div className={"quantity-container"}>
                <span onClick={decrementQuantity}>-</span>
                {quantity}
                <span onClick={incrementQuantity}>+</span>
              </div>
              <br />
              <button className={"add-to-cart-btn"} onClick={handleAddToCart}>ADD TO CART</button>
              <div className={"view-detail"}>View full details</div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default FirstTabCard;
