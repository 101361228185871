import React, { useState } from 'react';
import './ThirdTabwishlistProduct.css';
import bg1 from '../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp';
import bg2 from '../../assets/images/KhudaBaksh_30_720x.webp';
import heart from "../../assets/images/heart-svgrepo-com.svg";
import {Modal} from "react-bootstrap";

const ThirdTabWishlistProduct = ({discount,title,discounted_price,price,images}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [activeImg, setActiveImg] = useState(images && images.length > 0 ? images[0] : null);
    const [currentImage, setCurrentImage] = useState(images && images.length > 0 ? images[0] : null);
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState('Small');
    const [showModal, setShowModal] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleRoundImageHover = (image) => {
        setCurrentImage(image);
    };

    const handleRoundImageClick = (image) => {
        setCurrentImage(image);
        setIsHovered(true);
    };
    const handleQuickShopClick = () => {
        setShowModal(true);
    };
    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };
    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };
    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleRoundImageClick1 = (image) => {
        setActiveImg(image);
        setIsHovered(true);
    };
    return (
        <div className="third-tab-card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`third-image-container1 ${isHovered ? 'zoomed' : ''}`}>
                {isHovered ? (
                    <img
                        src={currentImage}
                        alt="Zoomed"
                        className="third-zoomed-image"
                    />
                ) : (
                    <img
                        src={images[1] ?? ""}
                        alt="Original"
                        className="third-original-image"
                    />
                )}

            </div>
            <div className={"discount-container"}>-{discount}%</div>
            <div className={"new-container"}>New</div>
            <div className={"icons-zoom-container1"}>
                <a className="tooltip-container" aria-label="Add to Favorites">
                   <i className={"fas fa-trash-alt"} style={{color:"black",fontSize:"17px",cursor:"pointer"}}/>
                    <span className="tooltip-text">Remove from Wishlist</span>
                </a>
                <a className="tooltip-container" aria-label="Add to Favorites">
                    <img src={heart} className={"icons-zoom-img"} alt="Heart Icon"/>
                    <span className="tooltip-text">Add to Favorites</span>
                </a>
            </div>
            {isHovered ? (
                <div className={"third-hover-text1"}>
                    <span>Small Medium Large Extra Large</span>
                    <button className={"quick-shop-btn"} onClick={handleQuickShopClick}>QuickShop</button>
                </div>
            ) : (
                ""
            )}

            <div className="content-card">
                <p className={"suit-title"}>{title}</p>
                <p className={"price-container-card"}>
                    <span className={"price"}>{discounted_price}</span>
                    <span className={"discounted-price"}>{price}</span>
                </p>
                <div className={"img-container"}>
                    {images.map((image_new, index) => {
                        return (
                            <img
                                key={index}
                                src={image_new}
                                alt="Zoomed"
                                className="img-round"
                                onMouseEnter={() => handleRoundImageHover(image_new)}
                                onClick={() => handleRoundImageClick(image_new)}
                            />
                        );
                    })}
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className={"detail-modal"}>
                        <div className={"detail-modal-top"}>
                            <img src={activeImg} alt={"not found"} />
                            <div className={"detail-modal-right"}>
                                <span className={"title"}>{title}</span>
                                <div className={"price-discount"}>
                                    <span className={"cancel-price"}>{discounted_price}</span>
                                    <span className={"price"}>{price}</span>
                                    <span className={"discount-box"}>{discount}%</span>
                                </div>
                            </div>
                        </div>
                        <div className={"colo-box"}>COLOR: BLACK</div>
                        <div className={"img-div"}>
                            <img className={"roud-img"} onClick={() => handleRoundImageClick1(images && images.length > 0 ? images[0] : '')} src={images && images.length > 0 ? images[0] : ''} alt={"not found"} />
                            <img className={"roud-img"} onClick={() => handleRoundImageClick1(images && images.length > 1 ? images[1] : '')} src={images && images.length > 1 ? images[1] : ''} alt={"not found"} />
                        </div>
                        <div className={"colo-box"}>SIZE: {selectedSize}</div>
                        <div className={"options-box"}>
                            <div className={`single-option ${selectedSize === 'Small' ? 'active' : ''}`}>
                                <input type={"radio"} checked={selectedSize === 'Small'} onChange={() => handleSizeChange('Small')} />SMALL
                            </div>
                            <div className={`single-option ${selectedSize === 'Medium' ? 'active' : ''}`}>
                                <input type={"radio"} checked={selectedSize === 'Medium'} onChange={() => handleSizeChange('Medium')} />MEDIUM
                            </div>
                            <div className={`single-option ${selectedSize === 'Large' ? 'active' : ''}`}>
                                <input type={"radio"} checked={selectedSize === 'Large'} onChange={() => handleSizeChange('Large')} />LARGE
                            </div>
                            <div className={`single-option ${selectedSize === 'X-Large' ? 'active' : ''}`}>
                                <input type={"radio"} checked={selectedSize === 'X-Large'} onChange={() => handleSizeChange('X-Large')} />X-LARGE
                            </div>
                        </div>
                        <div className={"quantity-container"}>
                            <span onClick={decrementQuantity}>-</span>
                            {quantity}
                            <span onClick={incrementQuantity}>+</span>
                        </div>
                        <br />
                        <button className={"add-to-cart-btn"}>ADD TO CART</button>
                        <div className={"view-detail"}>View full details</div>
                    </div>
                </Modal.Body>
                <Modal.Footer/>
            </Modal>
        </div>
    );
};

export default ThirdTabWishlistProduct;
