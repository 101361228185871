import './CartDiaries.css';
import clientDiary1 from "../../assets/images/client-diary1.webp";
import clientDiary4 from "../../assets/images/clientDiary-4.webp";
import clientDiary2 from "../../assets/images/client-diary2.webp";
import clientDiary3 from "../../assets/images/client-diary-3.webp";
import clientDiary5 from "../../assets/images/clientDiary-5.webp";
import clientDiary6 from "../../assets/images/clientDiary-6.webp";
import clientDiary7 from "../../assets/images/clientDiary-7.webp";
import clientDiary8 from "../../assets/images/clientDiary-8.webp";
import clientDiary9 from "../../assets/images/clientDiary-9.webp";
import axios from "axios";
import API_BASE_URL from "../../Constants";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";
const CartDiaries = ()=>{

    const dispatch = useDispatch();
    const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);
    const [client_diaries,setClientDiaries] = useState([]);
    const [static_client_diaries,setStaticClientDiaries] = useState([]);

    useEffect(() => {
        dispatch(fetchWebsiteConfigures());
    }, []);

    useEffect(() => {
        if (website_configures) {
            const data = website_configures.client_diaries ?? [];
            const staticDiaries = data.slice(0, 5);
            const dynamicBottomDiaries = data.slice(5);
            setStaticClientDiaries(staticDiaries);
            setClientDiaries(dynamicBottomDiaries ?? []);
        }
    }, [website_configures]);


    return(
        <>
            <div className={"cart-diaries-section"}>
                <div className={"cart-diaries-title"}>Client Diaries</div>
                <div className={"diaries-container-top"}>
                    <div className={"cart-diary-first"}>
                        <div className={"cart-diary-first-item"}
                             style={{ backgroundImage: `url(${API_BASE_URL+static_client_diaries[0]})` }}>
                        </div>
                        <div className={"cart-diary-second-item"}
                             style={{  backgroundImage: `url(${API_BASE_URL+static_client_diaries[1]})` }}>
                        </div>
                    </div>
                    <div className={"cart-diary-second"}
                         style={{  backgroundImage: `url(${API_BASE_URL+static_client_diaries[2]})`}}
                    />
                    <div className={"cart-diary-third"}>
                        <div className={"cart-diary-third-item"}
                             style={{ backgroundImage: `url(${API_BASE_URL+static_client_diaries[3]})`}}>
                        </div>
                        <div className={"cart-diary-four-item"}
                             style={{  backgroundImage: `url(${API_BASE_URL+static_client_diaries[4]})`}}
                        >
                        </div>
                    </div>
                </div>
                <div className={"diaries-container-bottom"}>
                    {client_diaries.map((item, index) => (
                        <div
                            key={index}
                            className={`diaries-bottom-first-item diaries-bottom-${index + 1}-item`}
                            style={{ backgroundImage: `url(${API_BASE_URL + item})` }}
                        />
                    ))}
                    {/*<div className={"diaries-bottom-first-item"}*/}
                    {/*     style={{backgroundImage:`url(${clientDiary6})`}}*/}
                    {/*/>*/}
                    {/*<div className={"diaries-bottom-second-item"}*/}
                    {/*     style={{  backgroundImage:`url(${clientDiary7})`}}*/}
                    {/*/>*/}
                    {/*<div className={"diaries-bottom-third-item"}*/}
                    {/*     style={{ backgroundImage:`url(${clientDiary8})`}}*/}
                    {/*/>*/}
                    {/*<div className={"diaries-bottom-four-item"}*/}
                    {/*    style={{backgroundImage:`url(${clientDiary9})`}}*/}
                    {/*/>*/}
                </div>
            </div>
        </>
    )
}
export default CartDiaries;