import React, {useEffect, useState} from 'react';
import './OurProducts.css';
import ZoomCard from '../Card/Card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cardimg1 from "../../assets/images/card_img1.webp";
import cardimg2 from "../../assets/images/card_img2.webp";
import card_img_4 from "../../assets/images/card_img_3.webp";
import cardimg3 from "../../assets/images/card_img3.webp";
import bg1 from "../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp";
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import axios from "axios";
import API_BASE_URL from "../../Constants";

const OurProducts = () => {
    const [activeTab, setActiveTab] = useState('sale');
    const [products,setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }
    const imgs = [bg1, bg2];

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}api/all-products`, {
                    headers: {
                        'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
                        'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
                    }
                });
                console.log(response.data);
                setProducts(response.data);
                setLoading(false);
            } catch (error) {
                setError("Failed to fetch products");
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const saleProducts = [
        {
            title: "F-153 - BASICS BY KHUDA BAKSH",
            discounted_price: "€26.31",
            price: "€19.72",
            sizes: ["Small", "Large", "Medium", "X-Large"],
            images: imgs,
            isNew: "New",
            discount: "-25%"
        },
        {
            title: "F-157",
            discounted_price: "€29.27",
            price: "€21.66",
            sizes: ["Small", "Medium", "Large"],
            images: [cardimg1,cardimg2],
            isNew: "New",
            discount: "-26%"
        },{
            title: "F-156",
            discounted_price: "€34.87",
            price: "€26.27",
            sizes: ["Small", "Medium", "Large"],
            images: [card_img_4,cardimg3],
            isNew: "New",
            discount: "-10%"
        },{
            title: "Another Product Title",
            discounted_price: "€XX.XX",
            price: "€YY.YY",
            sizes: ["Small", "Medium", "Large"],
            images: [bg1,bg2],
            isNew: "New",
            discount: "-10%"
        }
    ];
    const topRatedProducts = [
        {
            title: "F-153 - BASICS BY KHUDA BAKSH",
            discounted_price: "€26.31",
            price: "€19.72",
            sizes: ["Small", "Large", "Medium", "X-Large"],
            images: imgs,
            isNew: "New",
            discount: "-25%"
        },
        {
            title: "F-157",
            discounted_price: "€29.27",
            price: "€21.66",
            sizes: ["Small", "Medium", "Large"],
            images: [cardimg1,cardimg2],
            isNew: "New",
            discount: "-26%"
        },{
            title: "F-156",
            discounted_price: "€34.87",
            price: "€26.27",
            sizes: ["Small", "Medium", "Large"],
            images: [card_img_4,cardimg3],
            isNew: "New",
            discount: "-10%"
        },{
            title: "Another Product Title",
            discounted_price: "€XX.XX",
            price: "€YY.YY",
            sizes: ["Small", "Medium", "Large"],
            images: [bg1,bg2],
            isNew: "New",
            discount: "-10%"
        }
    ];

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
               <h1>Previous</h1>
            </div>
        );
    };
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="currentColor"/>
                </svg>
            </div>
        );
    };
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />
    };

    return (
        <div className="our-products">
            <h3 className="h-product">Our Products</h3>

            {/* Tabs */}
            <div className="tabs">
                <button className={`tab ${activeTab === 'sale' ? 'active' : ''}`} onClick={() => handleTabClick('sale')}>Sale</button>
                <button className={`tab ${activeTab === 'top-rated' ? 'active' : ''}`} onClick={() => handleTabClick('top-rated')}>Top Rated</button>
            </div>

            {/* Slider for Sale */}
            {activeTab === 'sale' && (
                <div className="slider-container">
                    <Slider {...sliderSettings}>
                        {products.map(item => (
                            <div key={item.id} style={{marginLeft:"70px"}}>
                                <ZoomCard
                                    product_id={item.id}
                                    title={item.name}
                                    discounted_price={item.purchase_price}
                                    price={item.unit_price}
                                    sizes={item.tags}
                                    images={item.images}
                                    isNew={"new"}
                                    discount={item.discount}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}

            {/* Slider for Top Rated */}
            {activeTab === 'top-rated' && (
                <div className="slider-container">
                    <Slider {...sliderSettings}>
                        {products.map(item => (
                            <div key={item.id}>
                                <ZoomCard
                                    product_id={item.id}
                                    title={item.name}
                                    discounted_price={item.purchase_price}
                                    price={item.unit_price}
                                    sizes={item.tags}
                                    images={item.images}
                                    isNew={"new"}
                                    discount={item.discount}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </div>
    );
}

export default OurProducts;
