import {createSlice} from '@reduxjs/toolkit';

const fetchWebsiteConfiguresSlice = createSlice({
    name: 'fetchWebsiteConfigures',
    initialState: {
        loading: false,
        website_configures: [],
        error: null,
    },
    reducers: {
        fetchWebsiteConfiguresRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchWebsiteConfiguresSuccess: (state, action) => {
            state.loading = false;
            state.website_configures = action.payload;
        },
        fetchWebsiteConfiguresFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchWebsiteConfiguresRequest,
    fetchWebsiteConfiguresSuccess,
    fetchWebsiteConfiguresFailure,
} = fetchWebsiteConfiguresSlice.actions;

export default fetchWebsiteConfiguresSlice.reducer;
