import './CollectionCard.css';;

const CollectionCard = ({img,product,title}) => {


    return (
        <div className={"collection-card"}>
            <img className={`collection-img `} src={img} alt={"Summer Collection"} />
            <span className={"collection-title"}>{title}</span>
            <br/>
            <span className={"collection-product"}>{product} products</span>
        </div>
    );
}

export default CollectionCard;
