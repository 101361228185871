import React, {useEffect, useState} from "react";
import './checkout.css';
import API_BASE_URL from "../../Constants";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {

    const navigate = useNavigate();
    const [cartData, setCartData] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [paymentGatewayId, setPaymentGatewayId] = useState(null);
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [postal_code, setPostalCode] = useState('');
    const [paymentGateways, setPaymentGateways] = useState([]);


    const handlePaymentSelect = (method,id) => {
        setSelectedPayment(method);
        setPaymentGatewayId(id);
    };


    const handleOrderSubmit = () => {

        const productIds = cartData.map(item => item.product_id);
        const quantities = cartData.map(item => item.quantity);

        if (!email) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill email  before placing the order!',
            });
        } else if (!firstName) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill First Name  before placing the order!',
            });
        } else if (!lastName) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill last Name  before placing the order!',
            });
        } else if (!address) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill  address  before placing the order!',
            });
        } else if (!city) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill  city  before placing the order!',
            });
        } else if (!paymentGatewayId) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill  payment gateway  before placing the order!',
            });
        } else if (!postal_code) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill  postal code  before placing the order!',
            });
        } else if (!phone) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill  phone  before placing the order!',
            });
        } else if (!selectedPayment) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Information',
                text: 'Please fill  payment  before placing the order!',
            });
        }else{
            const orderData = {
                product_ids: productIds,
                quantities: quantities,
                payment_gateway_id: paymentGatewayId,
                shipping_address:address,
                city: city,
                postal_code: postal_code,
                phone: phone,
                payment_method: selectedPayment,
                shipping_method: 'standard',
                email: email,
            };

            axios.post(`${API_BASE_URL}api/orders`, orderData,{
                headers: {
                    'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
                    'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Order Confirmed!',
                        text: 'Your order has been placed successfully.',
                        confirmButtonText: 'OK'
                    });
                    sessionStorage.removeItem('cart');
                    navigate('/');
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Order Failed',
                        text: 'Something went wrong while placing the order. Please try again.',
                    });
                });
        }
    };

    const fetchPaymentGateways = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}api/webPaymentGateways`, {
                headers: {
                    'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
                    'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
                }
            });
            setPaymentGateways(response.data.data);
        } catch (error) {
            console.error('Error fetching payment gateways:', error);
        }
    };

    useEffect(() => {
        const savedCart = JSON.parse(sessionStorage.getItem('cart')) || [];
        setCartData(savedCart);
    }, []);

    useEffect(() => {
        const total = cartData.reduce((sum, item) => {
            return sum + (item.price * item.quantity);
        }, 0);
        setSubtotal(total.toFixed(2));
    }, [cartData]);

    useEffect(() => {
        fetchPaymentGateways();
    }, []);

    return (
        <>
            <div className={"checkout-container"}>
                <div className={"checkout-header"}>
                    <div className={"checkout-header-heading"}>KHUDA BAKSH CREATIONS</div>
                    <i className={"fas fa-shopping-bag"}/>
                </div>
                <div className={"checkout-main"}>
                    <div className={"checkout-left"}>

                        <div className={"checkout-top-account-section"}>
                            <span>Account</span>
                            <i className={"fas fa-arrow-down"}/>
                        </div>
                        <div className={"email-logout"}>
                            <br/>
                            <input className={"input-new"} placeholder={"Email"}
                                   onChange={(e) => {
                                       setEmail(e.target.value);
                                   }}
                            />
                        </div>

                        <div className={"delivery-heading"}>Delivery</div>
                        <div className={"inputs-container-main"}>
                            {/*<div className={"input-box"}>*/}
                            {/*    <select className={"input-new"} style={{background: "white !important"}}>*/}
                            {/*        <option>Pakistan</option>*/}
                            {/*        <option>India</option>*/}
                            {/*        <option>England</option>*/}
                            {/*        <option>Iran</option>*/}
                            {/*        <option>Iraq</option>*/}
                            {/*        <option>Berland</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            <div className={"inputs-container"}>
                                <input className={"input-new"} placeholder={"First Name "}
                                       onChange={(e) => {
                                           setFirstName(e.target.value);
                                       }}
                                />
                                <input className={"input-new"} placeholder={"Last Name"}
                                       onChange={(e) => {
                                           setLastName(e.target.value);
                                       }}
                                />
                            </div>
                            <div className={"input-box"}>
                                <input className={"input-new"} placeholder={"Address"}
                                       onChange={(e) => {
                                           setAddress(e.target.value);
                                       }}
                                />
                            </div>
                            <div className={"inputs-container"}>
                                <input className={"input-new"} placeholder={"City"}
                                       onChange={(e) => {
                                           setCity(e.target.value);
                                       }}
                                />
                                <input className={"input-new"} placeholder={"Postal code (optional)"}

                                       onChange={(e) => {
                                           setPostalCode(e.target.value);
                                       }}/>
                            </div>
                            <div className={"input-box"}>
                                <input className={"input-new"} placeholder={"Phone"}
                                       onChange={(e) => {
                                           setPhone(e.target.value);
                                       }}
                                />
                            </div>
                        </div>
                        <div className={"delivery-heading"}>Shipping method</div>
                        <div className={"shipping-container"}>
                            <p>Free Shipping</p>
                            <span>Free</span>
                        </div>
                        <div className={"delivery-heading"}>Payment</div>
                        <div className={"text-small"}>All transactions are secure and encrypted.</div>
                        <br/>
                        <div className="payment-methods">
                            {paymentGateways.map((method) => (
                                <div
                                    key={method.id}
                                    className={`payment-option ${selectedPayment === method.name ? 'selected' : ''}`}
                                    onClick={() => handlePaymentSelect(method.name,method.id)} // Set selected payment method by name
                                >
                                    <img src={`${API_BASE_URL}storage/payment_gateway_images/${method.image}`}
                                         alt={method.name} className="payment-image"/>
                                    <p>{method.name}</p>
                                </div>
                            ))}
                        </div>

                        {selectedPayment && (
                            <div className="selected-payment-message">
                                Selected Payment
                                Method: {paymentGateways.find(method => method.name === selectedPayment).name}
                            </div>
                        )}

                        {/*<div className={"delivery-heading"}>Billing address</div>*/}

                        {/*<Accordion>*/}
                        {/*    <Accordion.Item eventKey="0">*/}
                        {/*        <Accordion.Header>Same as Shipping Address</Accordion.Header>*/}
                        {/*    </Accordion.Item>*/}
                        {/*    <Accordion.Item eventKey="1">*/}
                        {/*        <Accordion.Header>Use a different billing address</Accordion.Header>*/}
                        {/*        <Accordion.Body>*/}
                        {/*            <div className={"inputs-container-main"}>*/}
                        {/*                <div className={"input-box"}>*/}
                        {/*                    <select className={"input-new"} style={{background: "white !important"}}>*/}
                        {/*                        <option>Pakistan</option>*/}
                        {/*                        <option>India</option>*/}
                        {/*                        <option>England</option>*/}
                        {/*                        <option>Iran</option>*/}
                        {/*                        <option>Iraq</option>*/}
                        {/*                        <option>Berland</option>*/}
                        {/*                    </select>*/}
                        {/*                </div>*/}
                        {/*                <div className={"inputs-container"}>*/}
                        {/*                    <input className={"input-new"} placeholder={"First Name (optional)"}/>*/}
                        {/*                    <input className={"input-new"} placeholder={"Last Name"}/>*/}
                        {/*                </div>*/}
                        {/*                <div className={"input-box"}>*/}
                        {/*                    <input className={"input-new"} placeholder={"Address"}/>*/}
                        {/*                </div>*/}
                        {/*                <div className={"inputs-container"}>*/}
                        {/*                    <input className={"input-new"} placeholder={"City"}/>*/}
                        {/*                    <input className={"input-new"} placeholder={"Postal code (optional)"}/>*/}
                        {/*                </div>*/}
                        {/*                <div className={"input-box"}>*/}
                        {/*                    <input className={"input-new"} placeholder={"Phone"}/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </Accordion.Body>*/}
                        {/*    </Accordion.Item>*/}
                        {/*</Accordion>*/}
                        <button className={"complete-btn"} onClick={handleOrderSubmit}>Complete Order</button>
                    </div>
                    <div className={"checkout-right"}>
                        <div className={"checkouts-products-container"}>
                            {cartData.map((item, index) => (
                                <div className={"checout-product-container"} key={index}>
                                    <div className={"checkout-product-left"}>
                                        <div className={"checkout-product-img"}>
                                            <img
                                                src={item.images && item.images.length > 0 ? API_BASE_URL + item.images[0].file_location : 'fallback-image-url'}
                                                alt={"not found"} className={"checkout-product-image"}/>
                                            <div className={"checkout-quantity"}>{item.quantity}</div>
                                        </div>
                                        <div className={"checkoutproduct-detail"}>
                                            <div className={"checkout-product-title"}>
                                                {item.title}
                                            </div>
                                            <div className={"checkout-product-descrption"}>
                                                {item.selectedSize}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"checkout-product-right"}>
                                        Rs {item.price.toFixed(2)}
                                    </div>
                                </div>
                            ))}

                        </div>

                        <div className={"checkout-pricing-container"}>
                            <div className={"subtotal-container"}>
                                <span>Subtotal</span>
                                <span>Rs {subtotal}</span>
                            </div>
                            <div className={"subtotal-container"}>
                                <span>Shipping</span>
                                <span>FREE</span>
                            </div>
                            <div className={"total-container"}>
                                <b>Total</b>
                                <div className={"pricing-final"}>
                                    <span>PKR</span>
                                    <b>Rs {subtotal}</b>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Checkout;