import './FeaturedCollection.css';
import FeaturedCard from "../FeaturedCard/FeaturedCard";
import cardimg1 from "../../assets/images/card_img1.webp";
import cardimg2 from "../../assets/images/card_img2.webp";
import card_img_4 from "../../assets/images/card_img_3.webp";
import cardimg3 from "../../assets/images/card_img3.webp";
import bg1 from "../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp";
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import ZoomCard from "../Card/Card";
import React, {useEffect, useState} from "react";
import axios from "axios";
import API_BASE_URL from "../../Constants";

const FeaturedCollection = () => {

    const [showCount, setShowCount] = useState(10);
    const [products,setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const cards = Array.from({ length: 12 }, (_, index) => index + 1);
    const imgs = [bg1, bg2];


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}api/featured-products`, {
                    headers: {
                        'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
                        'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
                    }
                });
                console.log(response.data);
                setProducts(response.data);
                setLoading(false);
            } catch (error) {
                setError("Failed to fetch products");
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);
    return (
        <>
            <h3 className="h-featured">Featured collection</h3>
            <div className="featured-collection">
                {products.slice(0, showCount).map((item, index) => (
                    <FeaturedCard
                        key={index}
                        title={item.name}
                        discounted_price={item.purchase_price}
                        product_id={item.id}
                        price={item.unit_price}
                        sizes={item.tags}
                        images={item.images}
                        isNew={"new"}
                        discount={item.discount}
                    />
                ))}
            </div>
        </>
    );
}

export default FeaturedCollection;
