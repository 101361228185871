import './YouMightAlsoLikeIt.css';
import Slider from "react-slick";
import ZoomCard from "../Card/Card";
import React, {useEffect, useState} from "react";
import FeaturedCard from "../FeaturedCard/FeaturedCard";
import bg1 from "../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp";
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import axios from "axios";
import API_BASE_URL from "../../Constants";
const YouMightAlsoLikeIt = ()=>{
    const [products,setProducts] = useState([]);
    const [showCount, setShowCount] = useState(10);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const saleProducts = [
        { id: 1, img: 'https://via.placeholder.com/300x200', title: 'Product 1' },
        { id: 2, img: 'https://via.placeholder.com/300x200', title: 'Product 2' },
        { id: 3, img: 'https://via.placeholder.com/300x200', title: 'Product 3' },
    ];

    const imgs = [bg1, bg2];
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}api/featured-products`, {
                    headers: {
                        'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
                        'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
                    }
                });
                console.log(response.data);
                setProducts(response.data);
                setLoading(false);
            } catch (error) {
                setError("Failed to fetch products");
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);
    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <h1>Previous</h1>
            </div>
        );
    };
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="currentColor"/>
                </svg>
            </div>
        );
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />
    };
    return (
        <>
            <h3 className={"you-may-like-heading"}>You May also like</h3>

            <div className="slider-container" style={{marginTop:"40px"}}>
                <Slider {...sliderSettings}>
                    {products.map(item => (
                        <div key={item.id}>
                            <FeaturedCard
                                title={item.title}
                                discounted_price={item.discounted_price}
                                price={item.price}
                                sizes={item.sizes}
                                product_id={item.id}
                                images={item.images}
                                isNew={item.isNew}
                                discount={item.discount}
                            />
                        </div>
                    ))}
                </Slider>
            </div>

        </>
    )
}
export default YouMightAlsoLikeIt;