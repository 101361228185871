import React, { useState } from 'react';
import ReactImageZoom from "react-image-zoom";
import './imageslidercomponent.css';
import API_BASE_URL from "../../Constants";

const ImageSliderComponent = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleImageClick = (index) => {
        setActiveIndex(index);
    };

    const ImageZoomComponent = ({ img }) => {
        const props = {
            width: 400,
            height: 450,
            zoomWidth: 500,
            zoomHeight:600,
            img: img
        };

        return (
            <ReactImageZoom {...props} />
        );
    };

    return (
        <div  className={"img-slaider-top"}>
            <div  className={"img-slaider"}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={API_BASE_URL + image.file_location}
                        alt={`Image ${index + 1}`}
                        style={{ width: '120px', height: '80px', marginBottom: '10px', cursor: 'pointer', border: index === activeIndex ? '2px solid blue' : 'none' }}
                        onClick={() => handleImageClick(index)}
                    />
                ))}
            </div>
            <div className={"main-img"}>
                <ImageZoomComponent img={API_BASE_URL + images[activeIndex].file_location} alt={`Active Image ${activeIndex + 1}`}/>
                <div className={"discunt-container"}>
                    -26%
                </div>
                <div className={"ne-containr"}>
                    New
                </div>
            </div>
        </div>
    );
};

export default ImageSliderComponent;
