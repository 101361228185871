import "./Navbar.css";
import logo from "../../assets/images/logo.png";
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import search_img from "../../assets/images/search.svg";
import user from "../../assets/images/user.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import heart from "../../assets/images/heart-svgrepo-com.svg";
import cart_img from "../../assets/images/shopping-cart.png";
import React, {useEffect, useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import {NavLink, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import axios from "axios";
import {FaAngleDown, FaCaretDown} from "react-icons/fa6";
import API_BASE_URL from "../../Constants";
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const images = [bg2];
    const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);
    const [scrolled, setScrolled] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [activeImg, setActiveImg] = useState(
        images && images.length > 0 ? images[0] : null
    );
    const [selectedSize, setSelectedSize] = useState("Small");
    const [loginState, setLoginState] = useState("Login");
    const [showModal, setShowModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [modalItemIndex] = useState(null);
    const [activeTabCart, setActiveTabCart] = useState("default");
    const [email, setEmail] = useState("");
    const [new_email, setNewEmail] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [website_logo,setWebsiteLogo] = useState([]);

    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };

    useEffect(() => {
        dispatch(fetchWebsiteConfigures());
    }, []);

    useEffect(() => {
        const total = cartData.reduce((sum, item) => {
            return sum + (item.price * item.quantity);
        }, 0);
        setSubtotal(total.toFixed(2));
        if (website_configures) {
            setPhone(website_configures.phone ?? '');
            setWebsiteLogo(website_configures.logo_path ?? []);
        }
    }, [website_configures]);


    const handleRoundImageClick1 = (image) => {
        setActiveImg(image);
        setIsHovered(true);
    };

    const handleTabClickCart = (tab) => {
        setActiveTabCart(tab);
    };

    const handleShowModal = (item) => {
        setModalItem(item);
        setShowModal(true);
    };

    const decrementModelQuantity = () => {
        if (modalItemIndex !== null && cartData[modalItemIndex].quantity > 1) {
            const updatedCart = [...cartData];
            updatedCart[modalItemIndex].quantity--;
            setCartData(updatedCart);
        }
    };

    const incrementModelQuantity = () => {
        if (modalItemIndex !== null) {
            const updatedCart = [...cartData];
            updatedCart[modalItemIndex].quantity++;
            setCartData(updatedCart);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalItem(null);
    };

    const [cartShow, setCartShow] = useState(false);
    const [cartData, setCartData] = useState([]);

    const removeItemFromCart = (id) => {

        const updatedCart = cartData.filter((item) => item.product_id !== id);
        setCartData(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const [activeTab, setActiveTab] = useState("menu");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const incrementQuantity = (index) => {
        const updatedCart = [...cartData];
        updatedCart[index].quantity++;
        setCartData(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const decrementQuantity = (index) => {
        const updatedCart = [...cartData];
        if (updatedCart[index].quantity > 1) {
            updatedCart[index].quantity--;
            setCartData(updatedCart);
            sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        }
    };

    const handleLogin = () => {
        setLoginState("Login");
        handleLoginShow();
    };

    const handleClick = () => {
        const phoneNumber = phone;
        const message = encodeURIComponent(
            "Hello! I want to inquire about this product https://khudabaksh.com/account/addresses"
        );
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

        window.open(whatsappLink, "_blank");
    };

    const handleRegister = () => {
        setLoginState("Register");
        handleLoginShow();
    };

    const handleResetPassword = () => {
        setLoginState("Reset Password");
        handleLoginShow();
    };

    const [show, setShow] = useState(false);
    const [search, setSearch] = useState(false);
    const [cart, setCart] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLoginClose = () => setLoginModal(false);
    const handleLoginShow = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            setLoginModal(true);
        } else {
            navigate("/account");
        }
    };

    const handleCartShow = () => {
        const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
        setCartData(cart);
        setCartShow(true);
    };

    const handleCartClose = () => {
        setCartShow(false);
    };

    const handleSearchShow = () => {
        setSearch(true);
    };

    const handleSearchClose = () => {
        setSearch(false);
    };

    const handleLoginApi = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}api/login`, {
                email,
                password,
            });
            Swal.fire({
                title: "Success!",
                text: "Login successful!",
                icon: "success",
                confirmButtonText: "OK",
            });
            console.log(response.data);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user_id", response.data.user.id);
            handleLoginClose();
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Login failed. Please check your credentials.",
                icon: "error",
                confirmButtonText: "OK",
            });
            console.error(error);
        }
    };

    const isLoggedIn = localStorage.getItem('user_id');
    const handleRegisterApi = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}api/register`, {
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
                role: "user",
            });
            Swal.fire({
                title: "Success!",
                text: "Register successful!",
                icon: "success",
                confirmButtonText: "OK",
            });
            console.log(response.data);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user_id", response.data.user.id);
            handleLoginClose();
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "registration failed. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
            });

            console.error(error);
        }
    };
    const handleResetPasswordApi = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${API_BASE_URL}api/password/email`,
                {
                    email: new_email,
                }
            );
            Swal.fire({
                title: "Success!",
                text: "Password Reset Link Send to your Mail!",
                icon: "success",
                confirmButtonText: "OK",
            });
            console.log(response.data);
            handleLoginClose();
            setLoginState("Login");
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Invalid Email Address!",
                icon: "error",
                confirmButtonText: "OK",
            });
            console.error(error);
        }
    };

    const Logout = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        navigate('/');
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        const savedCart = JSON.parse(sessionStorage.getItem('cart')) || [];
        setCartData(savedCart);
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, [scrolled]);
    return (
        <>
            {windowWidth > 600 && (
                <div className={`navbar-c ${scrolled ? " scrolled" : ""}`}>
                    <Link to={"/"} className={"logo-container"}>
                        <img src={API_BASE_URL+website_logo[0]} className={"logo"} alt={"logo not found"}/>
                    </Link>
                    <div className={"tabs-container"}>
                        <NavLink to={"/"} className={"tab-box"}>
                            <div className={"tab-title"}>Home</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/new-arrivals"} className={"tab-box"}>
                            <div className={"tab-title"}>New Arrivals</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/summer-collection"} className={"tab-box"}>
                            <div className={"tab-title"}>Summer'24</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/basic-collection"} className={"tab-box"}>
                            <div className={"tab-title"}>Basics</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/luxury-collection"} className={"tab-box"}>
                            <div className={"tab-title"}>Luxury Pret</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/sale-collection"} className={"tab-box"}>
                            <div className={"tab-title"}>Sale</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/winter-collection"} className={"tab-box"}>
                            <div className={"tab-title"}>Winter'24</div>
                            <FaCaretDown/>
                        </NavLink>
                        <NavLink to={"/dupatta-collection"} className={"tab-box"}>
                            <div className={"tab-title"}>Dupatta |Shawl</div>
                            <FaCaretDown/>
                        </NavLink>
                    </div>

                    <div className={"icons-container"}>
                        <img
                            src={search_img}
                            alt={"not found"}
                            className={"icon"}
                            onClick={handleSearchShow}
                        />
                        <Offcanvas placement="end" show={search} onHide={handleSearchClose}>
                            <Offcanvas.Header className={"cart-header"} closeButton>
                                <Offcanvas.Title className={"cart-title"}>
                                    SEARCH OUR SITE
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className={"search-main-con"}>
                                    <input
                                        className={"search-inp"}
                                        placeholder={"Search for products"}
                                    />
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                        <img
                            src={user}
                            alt={"not found"}
                            className={"icon"}
                            id={"user_img"}
                            onClick={handleLoginShow}
                        />
                        {
                            isLoggedIn && (

                                <div className={"user-dropdown"}>
                                    <div className="user-dropdown-container">
                                        <Link
                                            to={"/account"}
                                            style={{textDecoration: "none", color: "grey"}}
                                        >
                                            <div className={"dropdown-item text-start"}>Dashboard</div>
                                        </Link>
                                        <Link
                                            to={"/account"}
                                            style={{textDecoration: "none", color: "grey"}}
                                        >
                                            <div className={"dropdown-item text-start"}>Addresses</div>
                                        </Link>
                                        <div className={"dropdown-item text-start"} onClick={Logout}>Logout</div>
                                    </div>
                                </div>

                            )
                        }

                        <Offcanvas
                            placement="end"
                            show={loginModal}
                            onHide={handleLoginClose}
                        >
                            {loginState === "Login" && (
                                <>
                                    <Offcanvas.Header className={"cart-header"} closeButton>
                                        <Offcanvas.Title className={"cart-title"}>
                                            LOGIN
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div className={"login-container"}>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>
                                                    Email <span>*</span>
                                                </label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"email"}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>
                                                    Password <span>*</span>
                                                </label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"password"}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <button
                                                className={"sign-in-btn"}
                                                onClick={(e) => {
                                                    handleLoginApi(e);
                                                }}
                                            >
                                                SIGN IN
                                            </button>
                                            <p className={"model-txt"}>
                                                New customer?
                                                <span
                                                    className={"color-brown"}
                                                    onClick={handleRegister}
                                                >
                          Create your account
                        </span>
                                            </p>
                                            <p className={"model-txt"}>
                                                Lost password?
                                                <span
                                                    className={"color-brown"}
                                                    onClick={handleResetPassword}
                                                >
                          Recover password
                        </span>
                                            </p>
                                        </div>
                                    </Offcanvas.Body>
                                </>
                            )}
                            {loginState === "Register" && (
                                <>
                                    <Offcanvas.Header className={"cart-header"} closeButton>
                                        <Offcanvas.Title className={"cart-title"}>
                                            REGISTER
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div className={"login-container"}>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>First Name</label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"text"}
                                                    value={first_name}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </div>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>Last Name</label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"text"}
                                                    value={last_name}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </div>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>
                                                    Email<span>*</span>
                                                </label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"email"}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>
                                                    Password <span>*</span>
                                                </label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"password"}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <p className={"model-tx"}>
                                                Your personal data will be used to support your
                                                experience throughout this website, to manage access to
                                                your account, and for other purposes described in our
                                                privacy policy.
                                            </p>
                                            <button
                                                className={"sign-in-btn"}
                                                onClick={(e) => {
                                                    handleRegisterApi(e);
                                                }}
                                            >
                                                REGISTER
                                            </button>
                                            <p className={"model-txt"}>
                                                Already have an account?
                                                <span className={"color-brown"} onClick={handleLogin}>
                          Login here
                        </span>
                                            </p>
                                        </div>
                                    </Offcanvas.Body>
                                </>
                            )}
                            {loginState === "Reset Password" && (
                                <>
                                    <Offcanvas.Header className={"cart-header"} closeButton>
                                        <Offcanvas.Title className={"cart-title"}>
                                            RECOVER PASSWORD
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div className={"password-container"}>
                                            <div className={"input-box"}>
                                                <label className={"form-label-login"}>
                                                    Email address
                                                </label>
                                                <input
                                                    className={"input"}
                                                    required
                                                    type={"email"}
                                                    value={new_email}
                                                    onChange={(e) => {
                                                        setNewEmail(e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <button
                                                className={"sign-in-btn"}
                                                onClick={(e) => {
                                                    handleResetPasswordApi(e);
                                                }}
                                            >
                                                RESET PASSWORD
                                            </button>
                                            <p className={"model-txt"}>
                                                Remember your password?
                                                <span className={"color-brown"} onClick={handleLogin}>
                          Back to login
                        </span>
                                            </p>
                                        </div>
                                    </Offcanvas.Body>
                                </>
                            )}
                        </Offcanvas>
                        <Link to={"/wishlist"}>
                            <img
                                src={heart}
                                alt={"not found"}
                                className={"icon heart-icon"}
                            />
                        </Link>
                        <div className={"wishlist-count-container"}>1</div>
                        <img
                            src={cart_img}
                            alt={"not fpound"}
                            className={"icon"}
                            onClick={handleCartShow}
                        />
                        <div className={"cart-count-container"}>1</div>
                        {cartData.length === 0 ? (
                            <Offcanvas
                                placement="end"
                                show={cartShow}
                                onHide={handleCartClose}
                            >
                                <Offcanvas.Header className={"cart-header"} closeButton>
                                    <Offcanvas.Title className={"cart-title"}>
                                        Shopping Cart
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className={"cart-main-con"}>
                                        <i className="fas fa-shopping-bag icon"/>
                                        <p className={"cart-main-text"}>Your cart is empty</p>
                                        <Link
                                            to={"/collection/all"}
                                            className={"cart-main-cont-button"}
                                        >
                                            RETURN TO SHOP
                                        </Link>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        ) : (
                            <Offcanvas
                                placement="end"
                                show={cartShow}
                                onHide={handleCartClose}
                            >
                                <Offcanvas.Header className={"cart-header"} closeButton>
                                    <Offcanvas.Title className={"cart-title"}>
                                        Shopping Cart
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className={"cart-data-container"}>
                                        {cartData.map((item, index) => (
                                            <div key={index}>
                                                <div className={"card-data-card"}>
                                                    <img
                                                        src={item.images && item.images.length > 0 ? API_BASE_URL + item.images[0].file_location : 'fallback-image-url'}
                                                        className={"cart-product-img"}
                                                        alt={"image not found"}
                                                    />
                                                    <div className={"cat-container-left"}>
                                                        <div className={"cart-product-title"}>
                                                            {item.title}
                                                        </div>
                                                        <div className={"product-cart-description"}>
                                                            {item.description}
                                                        </div>
                                                        <div className={"price-container"}>
                                                            <div className={"discounted_price"}>
                                                                €{item.price.toFixed(2)}
                                                            </div>
                                                            <div className={"price"}>
                                                                €{item.price.toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className={"quantity-container"}>
                              <span onClick={() => decrementQuantity(index)}>
                                -
                              </span>
                                                            {item.quantity}
                                                            <span onClick={() => incrementQuantity(index)}>
                                +
                              </span>
                                                        </div>
                                                        <div className={"cart-icon-container"}>
                                                            {/*<i*/}
                                                            {/*    className={"fas fa-edit"}*/}
                                                            {/*    onClick={() => handleShowModal(item)}*/}
                                                            {/*/>*/}
                                                            <i
                                                                className={"fas fa-trash-alt"}
                                                                onClick={() => removeItemFromCart(item.product_id)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Modal
                                        show={showModal}
                                        onHide={handleCloseModal}
                                        dialogClassName="custom-modal"
                                    >
                                        <Modal.Header closeButton/>
                                        <Modal.Body>
                                            <div className={"detail-modal"}>
                                                <div className={"detail-modal-top"}>
                                                    <img src={activeImg} alt={"not found"}/>
                                                    <div className={"detail-modal-right"}>
                                                        <span className={"title"}>{modalItem?.title}</span>
                                                        <div className={"price-discount"}>
                              <span className={"cancel-price"}>
                                {modalItem?.discounted_price}
                              </span>
                                                            <span className={"price"}>
                                {modalItem?.price}
                              </span>
                                                            <span className={"discount-box"}>
                                {modalItem?.discount}%
                              </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"colo-box"}>
                                                    COLOR: {modalItem?.color}
                                                </div>
                                                <div className={"img-div"}>
                                                    <img
                                                        className={"roud-img"}
                                                        onClick={() =>
                                                            handleRoundImageClick1(
                                                                images && images.length > 0 ? images[0] : ""
                                                            )
                                                        }
                                                        src={images && images.length > 0 ? images[0] : ""}
                                                        alt={"not found"}
                                                    />
                                                    <img
                                                        className={"roud-img"}
                                                        onClick={() =>
                                                            handleRoundImageClick1(
                                                                images && images.length > 1 ? images[1] : ""
                                                            )
                                                        }
                                                        src={images && images.length > 1 ? images[1] : ""}
                                                        alt={"not found"}
                                                    />
                                                </div>
                                                <div className={"colo-box"}>SIZE: {selectedSize}</div>
                                                <div className={"options-box"}>
                                                    <div
                                                        className={`single-option ${
                                                            selectedSize === "Small" ? "active" : ""
                                                        }`}
                                                    >
                                                        <input
                                                            type={"radio"}
                                                            checked={selectedSize === "Small"}
                                                            onChange={() => handleSizeChange("Small")}
                                                        />
                                                        SMALL
                                                    </div>
                                                    <div
                                                        className={`single-option ${
                                                            selectedSize === "Medium" ? "active" : ""
                                                        }`}
                                                    >
                                                        <input
                                                            type={"radio"}
                                                            checked={selectedSize === "Medium"}
                                                            onChange={() => handleSizeChange("Medium")}
                                                        />
                                                        MEDIUM
                                                    </div>
                                                    <div
                                                        className={`single-option ${
                                                            selectedSize === "Large" ? "active" : ""
                                                        }`}
                                                    >
                                                        <input
                                                            type={"radio"}
                                                            checked={selectedSize === "Large"}
                                                            onChange={() => handleSizeChange("Large")}
                                                        />
                                                        LARGE
                                                    </div>
                                                    <div
                                                        className={`single-option ${
                                                            selectedSize === "X-Large" ? "active" : ""
                                                        }`}
                                                    >
                                                        <input
                                                            type={"radio"}
                                                            checked={selectedSize === "X-Large"}
                                                            onChange={() => handleSizeChange("X-Large")}
                                                        />
                                                        X-LARGE
                                                    </div>
                                                </div>
                                                <div className={"quantity-container"}>
                                                    <span onClick={decrementModelQuantity}>-</span>
                                                    {modalItem?.quantity}
                                                    <span onClick={incrementModelQuantity}>+</span>
                                                </div>
                                                <br/>
                                                <button className={"add-to-cart-btn"}>
                                                    ADD TO CART
                                                </button>
                                                <div className={"view-detail"}>View full details</div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer/>
                                    </Modal>
                                    <div className={"bottom-container"}>
                                        <div className={"cart-tabs-container"}>
                                            <div
                                                className={`cart-tab ${
                                                    activeTabCart === "tab1" ? "active" : ""
                                                }`}
                                                onClick={() => handleTabClickCart("tab1")}
                                            >
                                                <i className={"fas fa-sticky-note"}/>
                                            </div>
                                            <div
                                                className={`cart-tab ${
                                                    activeTabCart === "tab2" ? "active" : ""
                                                }`}
                                                onClick={() => handleTabClickCart("tab2")}
                                            >
                                                <i className={"fas fa-car"}/>
                                            </div>
                                        </div>
                                        {activeTabCart === "default" && (
                                            <div className={"fixed-bottom-container"}>
                                                <div className={"top-fixed-box"}>
                                                    <div className={"subtotal-heading"}>Subtotal:</div>
                                                    <div className={"fixed-price"}>€{subtotal}</div>
                                                </div>
                                                <div className={"fixed-text"}>
                                                    Taxes and shipping calculated at checkout
                                                </div>
                                                <div className={"fixed-checkbox"}>
                                                    <input type={"checkbox"}/> I agree with the terms and
                                                    conditions.
                                                </div>
                                                <Link to={"/cart"} className={"view-cart-btn"}>
                                                    VIEW CART
                                                </Link>
                                                <div className={"fixed-text"}>
                                                    All charges are billed in PKR. While the content of
                                                    your cart is currently displayed in EUR, the checkout
                                                    will use PKR at the most current exchange rate.
                                                </div>
                                                <Link
                                                    to={"/checkout"}
                                                    style={{
                                                        textDecoration: "none",
                                                        textAlign: "center",
                                                    }}
                                                    className={"checkout-btn"}
                                                >
                                                    CHECK OUT
                                                </Link>
                                            </div>
                                        )}
                                        {activeTabCart === "tab1" && (
                                            <div className={"fixed-bottom-container"}>
                                                <div className={"card-bottom-title"}>
                                                    Add Order Note
                                                </div>
                                                <textarea
                                                    className={"bottom-textarea"}
                                                    placeholder={"How can we help you?"}
                                                />
                                                <button className={"checkout-btn"}> SAVE</button>
                                                <button
                                                    className={"cancel-btn"}
                                                    onClick={() => handleTabClickCart("default")}
                                                >
                                                    {" "}
                                                    CANCEL
                                                </button>
                                            </div>
                                        )}
                                        {activeTabCart === "tab2" && (
                                            <div className={"fixed-bottom-container"}>
                                                <div className={"card-bottom-title"}>
                                                    Estimate Shipping
                                                </div>
                                                <div className={"input-box"}>
                                                    <label>Country</label>
                                                    <select className={"input"}>
                                                        <option>Pakistan</option>
                                                        <option>India</option>
                                                    </select>
                                                </div>
                                                <div className={"input-box"}>
                                                    <label>Postal/Zip Code</label>
                                                    <input className={"input"}/>
                                                </div>
                                                <button className={"checkout-btn"}>
                                                    {" "}
                                                    CALCULATE SHIPPING
                                                </button>
                                                <button
                                                    className={"cancel-btn"}
                                                    onClick={() => handleTabClickCart("default")}
                                                >
                                                    {" "}
                                                    CANCEL
                                                </button>
                                                <div className={"fixed-text"}>
                                                    We found one shipping rate available for Pakistan.
                                                    Free Shipping at €0.00
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        )}
                    </div>
                </div>
            )}

            {windowWidth <= 600 && (
                <div className={`navbar-c-mobile ${scrolled ? " scrolled" : ""}`}>
                    <i className={"fas fa-bars"} onClick={handleShow}/>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <div className="tabs-mbl">
                                <button
                                    className={`tab ${activeTab === "menu" ? "active" : ""}`}
                                    onClick={() => handleTabClick("menu")}
                                >
                                    MENU
                                </button>
                                <button
                                    className={`tab ${
                                        activeTab === "categories" ? "active" : ""
                                    }`}
                                    onClick={() => handleTabClick("categories")}
                                >
                                    CATEGORIES
                                </button>
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {/* Slider for Sale */}
                            {activeTab === "menu" && (
                                <div className="slider-container">
                                    <div className={"slider-container-item"}>New Arrival</div>
                                    <div className={"slider-container-item"}>
                                        Summer Collection
                                    </div>
                                    <div className={"slider-container-item"}>Luxury Pret</div>
                                    <div className={"slider-container-item"}>Sale</div>
                                    <div className={"slider-container-item"}>Accessories</div>
                                    <div className={"slider-container-item"}>
                                        Winter Collection
                                    </div>
                                    <div className={"slider-container-item"}>Dupatta | Shawl</div>
                                    <div className={"slider-container-item"}>
                                        <span>Need help?</span>
                                        <div className={"flex"}>
                                            <i className={"fas fa-phone"}/>
                                            &nbsp; +92 3111 222258
                                        </div>
                                        <div className={"flex"}>
                                            <i className={"fa fa-mail"}/>
                                            &nbsp; sales@khudabakhsh.com
                                        </div>
                                    </div>

                                    <div className={"slider-container-item"}>
                                        <i className={"fas fa-heart"}/>
                                        &nbsp; Wishlist
                                    </div>
                                    <div
                                        className={"slider-container-item"}
                                        onClick={handleSearchShow}
                                    >
                                        <i className={"fas fa-search"}/>
                                        &nbsp; Search
                                    </div>
                                    <div className={"slider-container-item"}>
                                        <i className={"fas fa-user"}/>
                                        &nbsp; Login/Register
                                    </div>
                                </div>
                            )}

                            {/* Slider for Top Rated */}
                            {activeTab === "categories" && (
                                <div className="slider-container">
                                    <div className={"slider-container-item"}>All Collection</div>
                                    <div className={"slider-container-item"}>Accessories</div>
                                    <div className={"slider-container-item"}>Jewellery</div>
                                </div>
                            )}
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div className={"logo-container"}>
                        <img src={logo} className={"logo"} alt={"logo not found"}/>
                    </div>
                    <div className={"icons-container"}>
                        <img
                            src={search_img}
                            alt={"not found"}
                            className={"icon"}
                            onClick={handleSearchShow}
                        />
                        <img
                            src={cart_img}
                            alt={"not found"}
                            className={"icon"}
                            onClick={handleCartShow}
                        />
                        <Offcanvas placement="end" show={cartShow} onHide={handleCartClose}>
                            <Offcanvas.Header className={"cart-header"} closeButton>
                                <Offcanvas.Title className={"cart-title"}>
                                    Shopping Cart
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className={"cart-main-con"}>
                                    <i className="fas fa-shopping-bag icon"/>
                                    <p className={"cart-main-text"}>Your cart is empty</p>
                                    <Link
                                        to={"/collection/all"}
                                        className={"cart-main-cont-button"}
                                    >
                                        RETURN TO SHOP
                                    </Link>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>

                        <img src={heart} alt={"not found"} className={"icon heart-icon"}/>
                        <div className={"wishlist-count-container"}>1</div>
                        <img src={cart} alt={"not fpound"} className={"icon"}/>
                        <div className={"cart-count-container"}>1</div>
                    </div>
                </div>
            )}

            {windowWidth <= 600 && (
                <div className={`bottom-c-mobile ${scrolled ? " " : ""}`}>
                    <div className={"bottom-c-mobile-box"}>
                        <i className={"fa fa-cog"}/>
                        <p className={"bottom-tab"}>Shop</p>
                    </div>
                    <div className={"bottom-c-mobile-box"} onClick={handleSearchShow}>
                        <i className={"fa fa-search"}/>
                        <p className={"bottom-tab"}>Search</p>
                    </div>
                    <div className={"bottom-c-mobile-box-whishlist"}>
                        <i className={"fa fa-heart"}/>
                        <p className={"bottom-tab"}>Wishlist</p>
                    </div>

                    <div className={"wishlist-count-container-mobile"}>1</div>
                    <div className={"bottom-c-mobile-box"} onClick={handleCartShow}>
                        <i className={"fa fa-cart"}/>
                        <p className={"bottom-tab"}>Cart</p>
                    </div>
                    <div className={"cart-count-container-mobile"}>6</div>
                    <div className={"bottom-c-mobile-box"}>
                        <i className={"fa fa-user"}/>
                        <p className={"bottom-tab"}>Account</p>
                    </div>
                    <div className={"bottom-c-mobile-box"}>
                        <i className={"fa fa-user"}/>
                        <p className={"bottom-tab"}>Filter</p>
                    </div>
                </div>
            )}
            <div className={"bottom-whatsap-btn"} onClick={handleClick}>
                <i className={"fab fa-whatsapp"}/>
            </div>
        </>
    );
};
export default Navbar;
