import './Wishlist.css';
import TopHeader from "../../components/TopHeader/TopHeader";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import React, {useEffect, useState} from "react";
import FirstTabCard from "../../components/FirstTabCard/FirstTabCard";
import SecondTabCard from "../../components/SecondTabCard/SecondTabCard";
import ThirdTabCard from "../../components/ThirdTabCard/ThirdTabCard";
import bg1 from "../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp";
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import FirstTabWishlistProduct from "../../components/FirstTabWishlistProduct/FirstTabWishlistProduct";
import SecondTabWishlistProduct from "../../components/SecondTabWishlistProduct/SecondTabWishlistProduct";
import ThirdTabWishlistProduct from "../../components/ThirdTabWishlistProduct/ThirdTabWishlistProduct";
import FourTabWishlistProduct from "../../components/FourTabWishlistProduct/FourTabWishlistProduct";
const Wishlist = ()=>{
    const [wishlistData, setWishListData] = useState([{
        id: 1,
        title: 'F-154 - BASICS BY KHUD',
        description: 'Pale Turquoise / Small',
        color:'purpole',
        discounted_price:'234',
        price: 28.35,
        discount:"23%",
        quantity: 1
    }, {
        id: 2,
        title: 'F-154 - BASICS BY KHUD',
        description: 'Pale Turquoise / Small',
        color:'yellow',
        discount:"34%",
        discounted_price:'234',
        price: 28.35,
        quantity: 1
    }
    ]);
    const [filter,setFilter] = useState(false);
    const images = [bg1,bg2];
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <>
        <TopHeader/>
            <Navbar/>
            <div className={"wishlist-top-section"}>
                View your wishlist products
            </div>
            <div className={"AllCollectionHeader"}>
                <div className={"filter-container"} >
                </div>
                <div className={"grid-container"}>
                    <div className={activeTab === 1 ? "active first-grid-list" : "first-grid-list"}
                         onClick={() => handleTabClick(1)}>
                        <div className={"first-grid-item"}/>
                        <div className={"first-grid-item"}/>
                        <div className={"first-grid-item"}/>
                    </div>

                    <div className={activeTab === 2 ? "active second-grid-list" : "second-grid-list"}
                         onClick={() => handleTabClick(2)}>
                        <div className={"second-grid-item"}/>
                        <div className={"second-grid-item"}/>
                    </div>
                    {windowWidth > 600 && (
                        <div className={activeTab === 3 ? "active second-grid-list" : "second-grid-list"}
                             onClick={() => handleTabClick(3)}>
                            <div className={"second-grid-item"}/>
                            <div className={"second-grid-item"}/>
                            <div className={"second-grid-item"}/>
                        </div>
                    )}
                    {windowWidth > 600 && (
                        <div className={activeTab === 4 ? "active second-grid-list" : "second-grid-list"}
                             onClick={() => handleTabClick(4)}>
                            <div className={"second-grid-item"}/>
                            <div className={"second-grid-item"}/>
                            <div className={"second-grid-item"}/>
                            <div className={"second-grid-item"}/>
                        </div>
                    )}


                </div>
                <div className={"featured-cont"}>
                </div>
            </div>
            <div style={{width:"100%"}}>
            {wishlistData.length === 0 ? (
                    <div className={"wishlist-content"}>
                        <i className={"fas fa-heart wishlist-icon"}/>
                        <p className={"wishlist-title"}>WISHLIST IS EMPTY.</p>
                        <p className={"wishlist-txt"}>
                            You don't have  any products in the wishlist yet.
                            <br/>
                            You will find a lot of interesting products on our "Shop" page.
                        </p>
                        <button className={"wishlist-btn"}>RETURN TO SHOP</button>
                    </div>
            ) : (

            <div className="tab-content">
                <div className={activeTab === 1 ? "tab-panel active" : "tab-panel"}>
                    <div className={"grid-first-tab"}>
                       <div className={"first-tab-wishlist-product"}>
                           <FirstTabWishlistProduct
                               description={"READY TO WEAR Whats Included: Embroidered Shirt (Cotton (100% Pure) Sleeves Attached Trousers (Elasticized waistband) Dupatta Fabric Details: Shirt: (Cotton (100% Pure) Sleeves: Same as shirt Dupatta: Crinkle Chiffon Trousers: (Cotton (100%..."}
                               number={"23"} discount={"20"} to_price={"$30.9"} from_price={"$230"}
                               images={images}
                           />
                       </div>
                    </div>
                </div>
                <div className={activeTab === 2 ? "tab-panel active" : "tab-panel"}>
                    <div className={"grid-two-tab ml-20"}>
                        <SecondTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                       discounted_price={"$30.9"} price={"$230"} images={images}/>
                        <SecondTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                                  discounted_price={"$30.9"} price={"$230"} images={images}/>
                        <SecondTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                                  discounted_price={"$30.9"} price={"$230"} images={images}/>
                    </div>
                </div>
                <div className={activeTab === 3 ? "tab-panel active" : "tab-panel"}>
                    <div className={"grid-third-tab"}  style={{marginLeft:"20%"}}>
                        <ThirdTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                                  discounted_price={"$30.9"} price={"$230"} images={images}/>
                        <ThirdTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                                 discounted_price={"$30.9"} price={"$230"} images={images}/>
                        <ThirdTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                                 discounted_price={"$30.9"} price={"$230"} images={images}/>
                        <ThirdTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                                 discounted_price={"$30.9"} price={"$230"} images={images}/>
                    </div>
                </div>
                <div className={activeTab === 4 ? "tab-panel active" : "tab-panel"}>
                    <div className={"grid-four-tab"}  style={{marginLeft:"20%"}}>
                    <FourTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                             discounted_price={"$30.9"} price={"$230"} images={images}/>
                    <FourTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                            discounted_price={"$30.9"} price={"$230"} images={images}/>
                    <FourTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                            discounted_price={"$30.9"} price={"$230"} images={images}/>
                    <FourTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                            discounted_price={"$30.9"} price={"$230"} images={images}/>
                    <FourTabWishlistProduct title={"F-153 - BASICS BY KHUDA BAKSH"} discount={"20"}
                                            discounted_price={"$30.9"} price={"$230"} images={images}/>
                    </div>


                </div>
            </div>
            )}
            </div>
            <Footer/>
        </>
    )
}
export default Wishlist;