import './FirstTabWishlistProduct.css';
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import React, {useState} from "react";
import bg1 from "../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp";
import {Modal} from "react-bootstrap";

const FirstTabWishlistProduct = ({discount,from_price,to_price,description,number,images,title}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState('Small');
    const [activeImg, setActiveImg] = useState(images && images.length > 0 ? images[0] : null);
    const [currentImage, setCurrentImage] = useState(images && images.length > 0 ? images[0] : null);
    const [showModal, setShowModal] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleQuickShopClick = () => {
        setShowModal(true);
    };
    const handleRoundImageHover = (image) => {
        setCurrentImage(image);
    };
    const handleRoundImageClick = (image) => {
        setCurrentImage(image);
        setIsHovered(true);
    };

    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };
    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    };
    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleRoundImageClick1 = (image) => {
        setActiveImg(image);
        setIsHovered(true);
    };
    return (
        <>
            <div className={"first-tab-card"} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className={`first-image-container1 ${isHovered ? 'zoomed' : ''}`}>
                    {isHovered ? (
                        <img
                            src={currentImage}
                            alt="Zoomed"
                            className="first-zoomed-image"
                        />
                    ) : (
                        <img
                            src={images[1]}
                            alt="Original"
                            className="first-original-image"
                        />
                    )}

                    {isHovered ? (
                        <div className={"hover-text1-first"}>
                            <span>{title}</span>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className={"discount-container-first"}>-{discount}%</div>
                <div className={"new-container-first"}>New</div>
                {isHovered ? (
                    <div className={"delete-container"}>
                        <i className={"fas fa-trash-alt "}/></div>
                ) : (
                    ""
                )}

                <div className={"first-tab-wish-card-content"}>
                    <p className={"content-first"}>{number}</p>
                    <p className={"first-tab-whish--price"}><span className={"from"}>{from_price}</span>
                        <span className={"to"}>{to_price}</span></p>
                    <p className={"first-tab-wish-descrition"}>{description}</p>
                    <div className={"wish-img-container"}>
                        {images.map((image_new, index) => {
                            return (
                                <img
                                    key={index}
                                    src={image_new}
                                    alt="Zoomed"
                                    className="img-round"
                                    onMouseEnter={() => handleRoundImageHover(image_new)}
                                    onClick={() => handleRoundImageClick(image_new)}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={"firsst-ard-button-container"}>
                    <button className={"quick-view-btn"} onClick={handleQuickShopClick}>Quick View</button>
                    <button className={"read-more-btn"}>Read More</button>
                </div>
                <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal">
                    <Modal.Header closeButton/>
                    <Modal.Body>
                        <div className={"detail-modal"}>
                            <div className={"detail-modal-top"}>
                                <img src={activeImg} alt={"not found"} />
                                <div className={"detail-modal-right"}>
                                    <span className={"wishlist-title"}>{title}</span>
                                    <div className={"price-discount"}>
                                        <span className={"cancel-price"}>{from_price}</span>
                                        <span className={"price"}>{to_price}</span>
                                        <span className={"discount-box"}>{discount}%</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"colo-box"}>COLOR: BLACK</div>
                            <div className={"img-div"}>
                                <img className={"roud-img"} onClick={() => handleRoundImageClick1(images && images.length > 0 ? images[0] : '')} src={images && images.length > 0 ? images[0] : ''} alt={"not found"} />
                                <img className={"roud-img"} onClick={() => handleRoundImageClick1(images && images.length > 1 ? images[1] : '')} src={images && images.length > 1 ? images[1] : ''} alt={"not found"} />
                            </div>
                            <div className={"colo-box"}>SIZE: {selectedSize}</div>
                            <div className={"options-box"}>
                                <div className={`single-option ${selectedSize === 'Small' ? 'active' : ''}`}>
                                    <input type={"radio"} checked={selectedSize === 'Small'} onChange={() => handleSizeChange('Small')} />SMALL
                                </div>
                                <div className={`single-option ${selectedSize === 'Medium' ? 'active' : ''}`}>
                                    <input type={"radio"} checked={selectedSize === 'Medium'} onChange={() => handleSizeChange('Medium')} />MEDIUM
                                </div>
                                <div className={`single-option ${selectedSize === 'Large' ? 'active' : ''}`}>
                                    <input type={"radio"} checked={selectedSize === 'Large'} onChange={() => handleSizeChange('Large')} />LARGE
                                </div>
                                <div className={`single-option ${selectedSize === 'X-Large' ? 'active' : ''}`}>
                                    <input type={"radio"} checked={selectedSize === 'X-Large'} onChange={() => handleSizeChange('X-Large')} />X-LARGE
                                </div>
                            </div>
                            <div className={"quantity-container"}>
                                <span onClick={decrementQuantity}>-</span>
                                {quantity}
                                <span onClick={incrementQuantity}>+</span>
                            </div>
                            <br />
                            <button className={"add-to-cart-btn"}>ADD TO CART</button>
                            <div className={"view-detail"}>View full details</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer/>
                </Modal>
            </div>
        </>
    )
}
export default FirstTabWishlistProduct;