import axios from 'axios';
import API_BASE_URL from "../../Constants";
import {
    fetchWebsiteConfiguresRequest,
    fetchWebsiteConfiguresFailure,
    fetchWebsiteConfiguresSuccess,
} from './fetchWebsiteConfiuresSlice';

export const fetchWebsiteConfigures = () => async (dispatch) => {
    dispatch(fetchWebsiteConfiguresRequest());

    try {
        const apiUrl = `${API_BASE_URL}api/website_configures/web`;

        const headers = {
            'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
            'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J',
        };

        const response = await axios.get(apiUrl, { headers });
        dispatch(fetchWebsiteConfiguresSuccess(response.data.data));
    } catch (error) {
        dispatch(fetchWebsiteConfiguresFailure(error.message));
    }
};
