import React, { useState, useEffect } from 'react';
import './Hero.css';
import { Carousel } from 'react-bootstrap';
import bg1 from '../../assets/2_images_banner_Luxury_Pret_-_16x7.3web_R_1_3000x.jpg';
import bg2 from '../../assets/images/luxe_pret_Final_web_3000x.jpg';
import API_BASE_URL from "../../Constants";
import bg3 from '../../assets/images/P-239_banner_final_web_3000x.webp';
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";

const Hero = () => {

    const dispatch = useDispatch();
    const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);

    const [carouselImages, setCarouselImages] = useState([
        { src: bg1, alt: 'First slide' },
        { src: bg2, alt: 'Second slide' },
        { src: bg3, alt: 'Third slide' },
    ]);

    const [imagesData,setImagesData] = useState([]);

    useEffect(() => {
        dispatch(fetchWebsiteConfigures());
    }, []);

    useEffect(() => {
        if (website_configures) {
            setImagesData(website_configures.banners ?? []);
        }
    }, [website_configures]);


    return (
        <div className="hero">
            <Carousel indicators={true} controls={false} interval={4000} pause={true}>
                {imagesData.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={API_BASE_URL+image}
                            alt={""}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default Hero;
