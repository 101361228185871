import React, { useState, useEffect } from "react";
import "./NewArrivals.css";
import ZoomCard from "../Card/Card";
import axios from "axios";
import API_BASE_URL from "../../Constants";

const NewArrivals = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCount, setShowCount] = useState(10);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}api/new-arrivals-products`, {
                    headers: {
                        'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
                        'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
                    }
                });
                console.log(response.data.data);
                 setProducts(response.data.data);
                setLoading(false);
            } catch (error) {
                setError("Failed to fetch products");
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handleShowMore = () => {
        setShowCount((prevCount) => prevCount + 10);
    };

    if (loading) {
        return <div>Loading products...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <h4 className="heading-arrivals">New Arrivals</h4>
            <div className="new-arrivals-card">
                {products.slice(0, showCount).map((item, index) => (
                    <ZoomCard
                        key={index}
                        product_id={item.id}
                        title={item.name}
                        discounted_price={item.purchase_price}
                        price={item.unit_price}
                        sizes={item.tags}
                        images={item.images}
                        isNew={"new"}
                        discount={item.discount}
                    />
                ))}
            </div>
            {showCount < products.length && (
                <button className="show-more-btn" onClick={handleShowMore}>
                    Show More
                </button>
            )}
            <br />
            <button className="show-more-btn">See All Products</button>
            <div className="collections-container">
                {/* Additional content can go here */}
            </div>
        </>
    );
};

export default NewArrivals;
