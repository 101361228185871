import React from "react";
import './Cart.css';
import TopHeader from "../../components/TopHeader/TopHeader";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import CartProducts from "../../components/CartProducts/CartProducts";
import YouMightAlsoLikeIt from "../../components/YouMightAlsoLikeIt/YouMightAlsoLikeIt";
const Cart = () => {
    return (
        <>
            <TopHeader/>
            <Navbar/>
            <div className={"cart-top-section"}>
               SHOPPING CART
            </div>
            <CartProducts/>
<YouMightAlsoLikeIt/>
            <Footer/>
        </>
    )
}
export default Cart;