import React, {useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import Swal from 'sweetalert2';
import "./Card.css";
import heart from "../../assets/images/heart-svgrepo-com.svg";
import API_BASE_URL from "../../Constants";
import {Link} from "react-router-dom";

const ZoomCard = ({
                      discount,
                      isNew,
                      sizes,
                      title,
                      discounted_price,
                      price,
                      product_id,
                      images,
                  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState("Small");
    const [activeImg, setActiveImg] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const getImageUrl = (index) => {
        return images && images.length > index
            ? `${API_BASE_URL}${images[index].file_location}`
            : "placeholder.jpg";
    };

    const handleAddToCart = () => {
        const product = {
            title,
            discounted_price,
            product_id,
            price,
            images,
            quantity,
            selectedSize,
            discount,
        };

        let cart = JSON.parse(sessionStorage.getItem('cart')) || [];

        const productIndex = cart.findIndex((item) => item.product_id === product_id);

        if (productIndex !== -1) {
            cart[productIndex].quantity += quantity;
        } else {
            cart.push(product);
        }

        sessionStorage.setItem('cart', JSON.stringify(cart));
        console.log('Product added/updated in cart:', product);

        setShowModal(false);


        Swal.fire({
            icon: 'success',
            title: 'Added to Cart',
            text: `${product.title} has been added to your cart!`,
            showConfirmButton: false,
            timer: 2000,
        });

    };


    useEffect(() => {
        if (images && images.length > 0) {
            setActiveImg(getImageUrl(0));
            setCurrentImage(getImageUrl(0));
        }
    }, [images]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleRoundImageHover = (image) => {
        setCurrentImage(image);
    };

    const handleRoundImageClick = (image) => {
        setCurrentImage(image);
        setIsHovered(true);
        console.log("Clicked image: ", image);
    };

    const handleQuickShopClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleRoundImageClick1 = (image) => {
        setActiveImg(image);
        setIsHovered(true);
        console.log("Clicked round image: ", image);
    };

    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };

    const incrementQuantity = () => {
        setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity((prevQuantity) => prevQuantity - 1);
        }
    };

    return (
        <div
            className="zoom-card1"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={`image-container1 ${isHovered ? "zoomed" : ""} rounded-3`}
            >
                {isHovered ? (
                    <img src={currentImage} alt="Zoomed" className="zoomed-image"/>
                ) : (
                    <img
                        src={getImageUrl(1)}
                        alt="Original"
                        className="original-image"
                    />
                )}
            </div>
            <div className={"discount-container"}>{discount}%</div>
            <div className={"new-container"}>{isNew}</div>
            <div className={"icons-zoom-container1"}>
                <a href="/" className="tooltip-container" aria-label="Add to Favorites">
                    <img src={heart} className={"icons-zoom-img"} alt="Heart Icon"/>
                    <span className="tooltip-text">Add to Wishlist</span>
                </a>
                <a href="/" className="tooltip-container" aria-label="Add to Favorites">
                    <img src={heart} className={"icons-zoom-img"} alt="Heart Icon"/>
                    <span className="tooltip-text">Add to Favorites</span>
                </a>
            </div>
            {isHovered ? (
                <div className={"hover-text1"}>
                    <span>{sizes}</span>
                    <button className={"quick-shop-btn"} onClick={handleQuickShopClick}>
                        QuickShop
                    </button>
                </div>
            ) : null}
            <Link
                to={`/product/single/${product_id}`}
                style={{cursor: "pointer", color: "black", textDecoration: "none"}}
            >
                <div className="content-card">
                    <p className={"suit-title"}>{title}</p>
                    <p className={"price-container-card"}>
                        <span className={"price"}>{discounted_price}</span>
                        <span className={"discounted-price"}>{price}</span>
                    </p>
                    <div className={"img-container"}>
                        <img
                            src={getImageUrl(1)}
                            alt="Zoomed"
                            className="img-round"
                            onMouseEnter={() => handleRoundImageHover(getImageUrl(1))}
                            onClick={() => handleRoundImageClick(getImageUrl(1))}
                        />
                        <img
                            src={getImageUrl(0)}
                            alt="Original"
                            className="img-round"
                            onMouseEnter={() => handleRoundImageHover(getImageUrl(0))}
                            onClick={() => handleRoundImageClick(getImageUrl(0))}
                        />
                    </div>
                </div>
            </Link>
            <Modal show={showModal} onHide={handleCloseModal}
                   dialogClassName="custom-modal">
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className={"detail-modal"}>
                        <div className={"detail-modal-top"}>
                            <img src={activeImg} alt={"not found"}/>
                            <div className={"detail-modal-right"}>
                                <span className={"title"}>{title}</span>
                                <div className={"price-discount"}>
                                    <span className={"cancel-price"}>{discounted_price}</span>
                                    <span className={"price"}>{price}</span>
                                    <span className={"discount-box"}>{discount}%</span>
                                </div>
                            </div>
                        </div>
                        <div className={"colo-box"}>COLOR: BLACK</div>
                        <div className={"img-div"}>
                            <img className={"roud-img"}
                                 onClick={() => handleRoundImageClick1(getImageUrl(0))}
                                 src={getImageUrl(0)}
                                 alt={"not found"}/>
                            <img className={"roud-img"}
                                 onClick={() => handleRoundImageClick1(getImageUrl(1))}
                                 src={getImageUrl(1)}
                                 alt={"not found"}
                            />
                        </div>
                        <div className={"colo-box"}>SIZE: {selectedSize}</div>
                        <div className={"options-box"}>
                            <div className={`single-option ${
                                selectedSize === "Small" ? "active" : ""
                            }`}
                            >
                                <input type={"radio"}
                                       checked={selectedSize === "Small"}
                                       onChange={() => handleSizeChange("Small")}
                                />
                                SMALL
                            </div>
                            <div className={`single-option ${
                                selectedSize === "Medium" ? "active" : ""
                            }`}
                            >
                                <input type={"radio"}
                                       checked={selectedSize === "Medium"}
                                       onChange={() => handleSizeChange("Medium")}
                                />
                                MEDIUM
                            </div>
                            <div className={`single-option ${
                                selectedSize === "Large" ? "active" : ""
                            }`}
                            >
                                <input type={"radio"}
                                       checked={selectedSize === "Large"}
                                       onChange={() => handleSizeChange("Large")}
                                />
                                LARGE
                            </div>
                            <div className={`single-option ${
                                selectedSize === "X-Large" ? "active" : ""
                            }`}
                            >
                                <input type={"radio"}
                                       checked={selectedSize === "X-Large"}
                                       onChange={() => handleSizeChange("X-Large")}
                                />
                                X-LARGE
                            </div>
                        </div>
                        <div className={"quantity-container"}>
                            <span onClick={decrementQuantity}>-</span>
                            {quantity}
                            <span onClick={incrementQuantity}>+</span>
                        </div>
                        <br/>
                        <button className={"add-to-cart-btn"} onClick={handleAddToCart}>ADD TO CART</button>
                        <div className={"view-detail"}>View full details</div>
                    </div>
                </Modal.Body>
                <Modal.Footer/>
            </Modal>
        </div>
    );
};

export default ZoomCard;