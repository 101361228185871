import './CartProduct.css';
import React, {useEffect, useState} from "react";
import API_BASE_URL from "../../Constants";
import {Link} from "react-router-dom";

const CartProducts = () => {

    const [cartData, setCartData] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const removeItemFromCart = (id) => {
        const updatedCart = cartData.filter(item => item.product_id !== id);
        setCartData(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const incrementQuantity = (index) => {
        const updatedCart = [...cartData];
        updatedCart[index].quantity++;
        setCartData(updatedCart);
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const decrementQuantity = (index) => {
        const updatedCart = [...cartData];
        if (updatedCart[index].quantity > 1) {
            updatedCart[index].quantity--;
            setCartData(updatedCart);
            sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        }
    };

    useEffect(()=>{
        const savedCart = JSON.parse(sessionStorage.getItem('cart')) || [];
        setCartData(savedCart);
        const total = cartData.reduce((sum, item) => {
            return sum + (item.price * item.quantity);
        }, 0);
        setSubtotal(total.toFixed(2));
    });

    return (
        <>
            <div className={"card-product-container"}>
                <div className={"top-section row"}>
                    <div className={"col-6"}>
                        <div className={"heading"}>
                            PRODUCT
                        </div>
                    </div>
                    <div className={"col-2"}>
                        <div className={"heading"}>
                            PRICE
                        </div>
                    </div>
                    <div className={"col-2"}>
                        <div className={"heading"}>
                            QUANTITY
                        </div>
                    </div>
                    <div className={"col-2"}>
                        <div className={"heading"} style={{textAlign:"center"}}>
                            TOTAL
                        </div>
                    </div>
                </div>
                {
                  cartData.map((cart,index)=>{
                      return <>
                          <div className={"shopping-cart-product row"}>
                              <div className={"w-60"}>
                                  <div className={"cart-product-main"}>
                                      <img src={cart.images && cart.images.length > 0 ? API_BASE_URL + cart.images[0].file_location : 'fallback-image-url'}
                                           className={"cart-product-image"} alt={"image not found"}/>
                                      <div className={"cart-product-left-box"}>
                                          <div className={"cart-product-title"}>{cart.title}</div>
                                          <div className={"cart-product-color-box"}>Color:<span>{cart.color}</span></div>
                                          <div className={"cart-product-color-box"}>Size:<span>Small</span></div>
                                          <div className={"cart-icon-container"}>
                                              {/*<i className={"fas fa-edit"}/>*/}
                                              <i className={"fas fa-trash-alt"} onClick={()=>{removeItemFromCart(cart.product_id)}}/>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className={" column-box"}>
                                  <div className={"price-container cart-price-box"}>
                                      <div className={"discounted_price"}>€{cart.discounted_price}</div>
                                      <div className={"price"}>€{cart.price}</div>
                                  </div>
                              </div>
                              <div className={" column-box"}>
                                  <div className={"product-cart-quantity-container"}>
                                      <span onClick={()=>{
                                          decrementQuantity(index)
                                      }}>-</span>
                                      {cart.quantity}
                                      <span onClick={()=>{
                                              incrementQuantity(index)
                                          }}
                                      >+</span>
                                  </div>
                              </div>
                              <div className={"column-box"}>
                                  <div className={"total-price"}>€{cart.price * cart.quantity}</div>
                              </div>
                          </div>
                      </>
                  })
                }


            </div>
            <div className={"order-section"}>
                <div className={"order-first-section"}>
                    <div className={"order-heading"}>Add Order Note</div>
                    <textarea placeholder={"How can we help you ?"} className={"order-textarea"}/>
                </div>
                <div className={"order-second-section"}>
                    <div className={"subtotal-box"}>
                        <div className={"subtotal"}>SUBTOTAL:</div>
                        <span>{subtotal}</span>
                    </div>
                    <div className={"fixed-text-new"}>Taxes and shipping calculated at checkout</div>
                    <div className={"fixed-checkbox"}>
                        <input type={"checkbox"}/>I agree with the terms and conditions.
                    </div>
                    <div className={"fixed-text-new"}>All charges are billed in PKR. While the content of your cart is
                        currently displayed in EUR, the checkout will use PKR at the most current exchange rate.
                    </div>
                    <Link  to={"/checkout"} style={{textDecoration:"none"}} className={"checkout-btn"}>CHECKOUT</Link>
                </div>
            </div>
            <div className={"shipping-section"}>
              <div className={"estimate-shipping"}>Estimate Shipping</div>
                <div className={"main-shipping-container row"}>
                    <div className={"input-container "}>
                        <label>Country</label>
                        <select className={"input"}>
                            <option>Pakistan</option>
                            <option>India</option>
                        </select>
                    </div>
                    <div className={"input-container "}>
                        <label>Postal/Zip Code</label>
                        <input className={"input"}/>
                    </div>
                    <button className={"checkout-button "}>CALCULATE SHIPPING</button>
                </div>
            </div>
        </>
    )
}
export default CartProducts;