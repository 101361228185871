import React, {useEffect, useState} from "react";
import './Account.css';
import TopHeader from "../../components/TopHeader/TopHeader";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../Constants";
import Swal from "sweetalert2";

const Account = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('dashboard');
    const [user, setUser] = useState({});
    const [userAddresses, setUserAddresses] = useState([]);
    const [currentAddress, setCurrentAddress] = useState(null);
    const [user_first_name, setUserFirstName] = useState();
    const [user_last_name, setUserLastName] = useState();
    const [user_company, setCompany] = useState();
    const [user_address, setUSerAddress] = useState();
    const [user_apartment, setUSerApartment] = useState();
    const [user_city, setUSerCity] = useState();
    const [user_country, setUserCountry] = useState();
    const [user_zip_code, setUserZipCode] = useState();
    const [user_phone, setUserPhone] = useState();
    const [user_default_address, setUserDefaultAddress] = useState(false);
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [editingAddressId, setEditingAddressId] = useState(null);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsAddingAddress(false);
        setEditingAddressId(null);
    };

    const showAddAddressForm = () => {
        setIsAddingAddress(true);
        setEditingAddressId(null);
    };

    const showEditAddressForm = (addressId) => {
        setEditingAddressId(addressId);
        setIsAddingAddress(false);
        const addressToEdit = userAddresses.find(addr => addr.id === addressId);
        setCurrentAddress(addressToEdit);
    };

    const closeAddressForms = () => {
        setIsAddingAddress(false);
        setEditingAddressId(null);
        setCurrentAddress(null);
    };

    const Logout = () => {
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        navigate('/');
    };

    const fetchUser = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_BASE_URL}api/user/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setUser(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUserAddresses = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_BASE_URL}api/user_address/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setUserAddresses(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const addUserAddress = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${API_BASE_URL}api/user_addresses`, {
                user_id: localStorage.getItem('user_id'),
                first_name: user_first_name,
                last_name: user_last_name,
                address: user_address,
                city: user_city,
                country: user_country,
                zip_code: user_zip_code,
                phone: user_phone,
                is_default: user_default_address,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            Swal.fire({
                title: 'Success!',
                text: 'Address added successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            window.location.reload();
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to add a new address!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const deleteUserAddress = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.delete(`${API_BASE_URL}api/user_addresses/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            Swal.fire({
                title: 'Success!',
                text: 'Deleted successful!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            fetchUserAddresses(localStorage.getItem('user_id'));
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to Delete. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const updateUserAddress = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(
                `${API_BASE_URL}api/user_addresses/${editingAddressId}`,
                {
                    user_id: localStorage.getItem('user_id'),
                    ...currentAddress,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            Swal.fire({
                title: 'Success!',
                text: 'Address updated successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            window.location.reload();
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to update the address!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    useEffect(() => {
        const user_id = localStorage.getItem('user_id');
        if (user_id) {
            fetchUser(user_id);
            fetchUserAddresses(user_id);
        }
    }, []);

    const handleAddressChange = (e) => {
        const {name, value, type, checked} = e.target;
        setCurrentAddress(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    return (
        <>
            <TopHeader/>
            <Navbar/>
            <div className={"top-account-section"}>
                MY ACCOUNT
            </div>
            <div className={"account-container"}>
                <div className={"account-sidebar"}>
                    <div className={`account-sidebar-item ${activeTab === 'dashboard' ? 'active' : ''}`}
                         onClick={() => handleTabClick('dashboard')}>Dashboard
                    </div>
                    <div className={`account-sidebar-item ${activeTab === 'address' ? 'active' : ''}`}
                         onClick={() => handleTabClick('address')}>Addresses ({userAddresses.length})
                    </div>
                    <Link to={"/wishlist"} style={{color: "black", textDecoration: "none"}}>
                        <div className={"account-sidebar-item"}>Wishlist (0)</div>
                    </Link>
                    <div className={"account-sidebar-item"} onClick={Logout}>Logout</div>
                </div>
                {activeTab === 'dashboard' && (
                    <div className={"account-main-container"}>
                        <div className={"hello-para"}>
                            Hello <span>{user?.first_name + " " + user?.last_name ?? ""}</span>(not <span>{user?.first_name + " " + user?.last_name ?? ""}</span>? <a
                            style={{cursor: "pointer"}} onClick={Logout}>Logout</a>)
                        </div>
                        <div className={"bold-order"}>Order History</div>
                        <div className={"order-section"}>
                            <i className="fas fa-check"/>
                            <b>MAKE YOUR FIRST ORDER</b>
                            <p>You haven't placed any orders yet.</p>
                        </div>
                        <div className={"bold-order"}>Account details :</div>
                        <div className={"info-div"}>
                            <div className={"info-section"}>
                                <div className={"title"}>Name:</div>
                                <div className={"value"}>{user?.first_name + " " + user?.last_name ?? ""}</div>
                            </div>
                            <div className={"info-section"}>
                                <div className={"title"}>Email:</div>
                                <div className={"value"}>{user?.email ?? ""}</div>
                            </div>
                            <div className={"info-section"}>
                                <div className={"title"}>Address:</div>
                                <div className={"value"}/>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab === 'address' && (
                    <div className={"address-main-container"}>
                        <button className={"address-btn"} onClick={showAddAddressForm}>ADD A NEW ADDRESS</button>
                        {isAddingAddress && (
                            <div className={"add-address-container"}>
                                <div className={"address-heading"}>ADD A NEW ADDRESS</div>
                                <div className={"inputs-container"}>

                                    <div className={"input-box"}>
                                        <label>First Name</label>
                                        <input className={"input"}
                                            type={"text"}
                                            autoComplete={"off"}
                                            name="first_name"
                                            value={user_first_name ?? ""}
                                            onChange={(e) => {
                                                setUserFirstName(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className={"input-box"}>
                                        <label>Last Name</label>
                                        <input className={"input"}
                                            type={"text"}
                                            autoComplete={"off"}
                                            name="last_name"
                                            value={user_last_name ?? ""}
                                            onChange={(e) => {
                                                setUserLastName(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={"input-box"}>
                                    <label>Company</label>
                                    <input className={"input"}
                                        autoComplete={"off"}
                                        name="company"
                                        value={user_company ?? ""}
                                        onChange={(e) => {
                                            setCompany(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className={"input-box"}>
                                    <label>Address</label>
                                    <input className={"input"}
                                        name="address"
                                        autoComplete={"off"}
                                        value={user_address ?? ""}
                                        onChange={(e) => {
                                            setUSerAddress(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className={"input-box"}>
                                    <label>Apartment, suite, etc.</label>
                                    <input className={"input"}
                                        autoComplete={"off"}
                                        name="apartment"
                                        value={user_apartment ?? ""}
                                        onChange={(e) => {
                                            setUSerApartment(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className={"inputs-container"}>
                                    <div className={"input-box"}>
                                        <label>City</label>
                                        <input className={"input"}
                                            name="city"
                                            autoComplete={"off"}
                                            value={user_city ?? ""}
                                            onChange={(e) => {
                                                setUSerCity(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className={"input-box"}>
                                        <label>Country</label>
                                        <select className={"input"}
                                                name="country" value={user_country ?? ""}
                                                onChange={(e) => {
                                                    setUserCountry(e.target.value)
                                                }}>
                                            <option>Select Country</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="India">India</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={"input-box"}>
                                    <label>Postal/Zip Code</label>
                                    <input className={"input"}
                                        name="zip_code"
                                        autoComplete={"off"}
                                        value={user_zip_code ?? ""}
                                        onChange={(e) => {
                                            setUserZipCode(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className={"input-box"}>
                                    <label>Phone</label>
                                    <input className={"input"}
                                        name="phone"
                                        autoComplete={"off"}
                                        value={user_phone ?? ""}
                                        onChange={(e) => {
                                            setUserPhone(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className={"chec-box"}>
                                    <input type={"checkbox"}
                                        className="checkboc"
                                        name="is_default"
                                        checked={user_default_address ?? false}
                                        onChange={(e) => {
                                            setUserDefaultAddress(e.target.value)
                                        }
                                        }
                                    />
                                    Set as default address
                                </div>
                                <button className={"edit-btn-new"}
                                    onClick={isAddingAddress ? addUserAddress : updateUserAddress}
                                >
                                    {isAddingAddress ? 'Add Address' : 'Update Address'}
                                </button>
                                <button className={"edit-btn-new"} onClick={closeAddressForms}>Cancel</button>
                            </div>
                        )}

                        {userAddresses?.map((address) => (
                            <React.Fragment key={address.id}>
                                {address.is_default && <div className={"default"}>DEFAULT</div>}
                                <div className={"thin-text"}>
                                    {address.first_name + " " + address.last_name ?? ""}
                                </div>
                                <div className={"action-container"}>
                                    <div className={"edit-btn"}
                                        onClick={() => showEditAddressForm(address.id)}
                                    >
                                        Edit
                                    </div>
                                    <div className={"edit-btn"}
                                        onClick={() => deleteUserAddress(address.id)}
                                    >
                                        Delete
                                    </div>
                                </div>
                                {editingAddressId === address.id && (
                                    <div className={"edit-address-container"}>
                                        <div className={"address-heading"}>EDIT ADDRESS</div>
                                        <div className={"inputs-container"}>
                                            <div className={"input-box"}>
                                                <label>First Name</label>
                                                <input
                                                    className={"input"}
                                                    type={"text"}
                                                    name="first_name"
                                                    value={currentAddress?.first_name ?? ""}
                                                    onChange={handleAddressChange}
                                                />
                                            </div>
                                            <div className={"input-box"}>
                                                <label>Last Name</label>
                                                <input
                                                    className={"input"}
                                                    type={"text"}
                                                    name="last_name"
                                                    value={currentAddress?.last_name ?? ""}
                                                    onChange={handleAddressChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={"input-box"}>
                                            <label>Company</label>
                                            <input
                                                className={"input"}
                                                name="company"
                                                value={currentAddress?.company ?? ""}
                                                onChange={handleAddressChange}
                                            />
                                        </div>
                                        <div className={"input-box"}>
                                            <label>Address</label>
                                            <input
                                                className={"input"}
                                                name="address"
                                                value={currentAddress?.address ?? ""}
                                                onChange={handleAddressChange}
                                            />
                                        </div>
                                        <div className={"input-box"}>
                                            <label>Apartment, suite, etc.</label>
                                            <input
                                                className={"input"}
                                                name="apartment"
                                                value={currentAddress?.apartment ?? ""}
                                                onChange={handleAddressChange}
                                            />
                                        </div>
                                        <div className={"inputs-container"}>
                                            <div className={"input-box"}>
                                                <label>City</label>
                                                <input
                                                    className={"input"}
                                                    name="city"
                                                    value={currentAddress?.city ?? ""}
                                                    onChange={handleAddressChange}
                                                />
                                            </div>
                                            <div className={"input-box"}>
                                                <label>Country</label>
                                                <select
                                                    className={"input"}
                                                    name="country"
                                                    value={currentAddress?.country ?? ""}
                                                    onChange={handleAddressChange}
                                                >
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="India">India</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={"input-box"}>
                                            <label>Postal/Zip Code</label>
                                            <input
                                                className={"input"}
                                                name="zip_code"
                                                value={currentAddress?.zip_code ?? ""}
                                                onChange={handleAddressChange}
                                            />
                                        </div>
                                        <div className={"input-box"}>
                                            <label>Phone</label>
                                            <input
                                                className={"input"}
                                                name="phone"
                                                value={currentAddress?.phone ?? ""}
                                                onChange={handleAddressChange}
                                            />
                                        </div>
                                        <div className={"chec-box"}>
                                            <input
                                                type={"checkbox"}
                                                className="checkboc"
                                                name="is_default"
                                                checked={currentAddress?.is_default ?? false}
                                                onChange={handleAddressChange}
                                            />
                                            Set as default address
                                        </div>
                                        <button
                                            className={"edit-btn-new"}
                                            onClick={updateUserAddress}
                                        >
                                            Update Address
                                        </button>
                                        <button className={"edit-btn-new"} onClick={closeAddressForms}>Cancel</button>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
            <Footer/>
        </>
    );
};

export default Account;
