import React, { useEffect, useState } from "react";
import "./NewArrivals.css";
import "rc-slider/assets/index.css";
import TopHeader from "../../components/TopHeader/TopHeader";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import FourTabCard from "../../components/FourTabCard/FourTabCard";
import ThirdTabCard from "../../components/ThirdTabCard/ThirdTabCard";
import SecondTabCard from "../../components/SecondTabCard/SecondTabCard";
import bg1 from "../../assets/images/KhudaBaksh_7_bbc01433-2385-4584-866e-4a24edff8ba0_720x.webp";
import bg2 from "../../assets/images/KhudaBaksh_30_720x.webp";
import FirstTabCard from "../../components/FirstTabCard/FirstTabCard";
import Slider from "rc-slider";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import API_BASE_URL from "../../Constants";const NewArrivals = () => {

  const dispatch = useDispatch();
  const [value, setValue] = React.useState(50);
  const [filter, setFilter] = useState(false);
  const images = [bg1, bg2];
  const [activeTab, setActiveTab] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [products,setProducts] = useState([]);
  const [showCount, setShowCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const ToggleFilter = () => {
    if (filter) {
      setFilter(false);
    } else {
      setFilter(true);
    }
  };
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const handleSliderChange = (value) => {
    setValue(value);
  };

  const renderItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    console.log(products);

    const itemsToRender = products.slice(startIndex, endIndex).map((item, index) => (
        <FourTabCard
            key={item.id || index}
            product_id={item.id}
            sizes={item.sizes}
            description={item.description}
            number={"23"}
            title={item.name}
            discounted_price={item.discount_price}
            discount={item.discount}
            price={item.unit_price}
            images={item.images}
        />
    ));

    return <div className={"grid-four-tab"}>{itemsToRender}</div>;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationButtons = () => {
    const pageCount = Math.ceil(20 / itemsPerPage);
    const buttons = [];

    buttons.push(
      <button
        key={"prev"}
        className="pagination-button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"< Previous"}
      </button>
    );

    for (let i = 1; i <= pageCount; i++) {
      buttons.push(
        <button
          key={i}
          className={
            currentPage === i ? "pagination-button active" : "pagination-button"
          }
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    buttons.push(
      <button
        key={"next"}
        className="pagination-button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
      >
        {"Next >"}
      </button>
    );

    return buttons;
  };



  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}api/new-arrivals-products`, {
          headers: {
            'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
            'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J'
          }
        });
        console.log(response.data.data);
        setProducts(response.data.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch products");
        setLoading(false);
      }
    };

    window.addEventListener("resize", handleResize);
    fetchProducts();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <TopHeader />
      <Navbar />
      <div className={"AllCollectionHeader"}>
        <div className={"filter-container"} onClick={ToggleFilter}>
          <i className={"fas fa-toolbox"} />
          &nbsp; Filter
        </div>
        <div className={"grid-container"}>
          <div
            className={
              activeTab === 1 ? "active first-grid-list" : "first-grid-list"
            }
            onClick={() => handleTabClick(1)}
          >
            <div className={"first-grid-item"} />
            <div className={"first-grid-item"} />
            <div className={"first-grid-item"} />
          </div>
          <div
            className={
              activeTab === 2 ? "active second-grid-list" : "second-grid-list"
            }
            onClick={() => handleTabClick(2)}
          >
            <div className={"second-grid-item"} />
            <div className={"second-grid-item"} />
          </div>
          {windowWidth > 600 && (
            <div
              className={
                activeTab === 3 ? "active second-grid-list" : "second-grid-list"
              }
              onClick={() => handleTabClick(3)}
            >
              <div className={"second-grid-item"} />
              <div className={"second-grid-item"} />
              <div className={"second-grid-item"} />
            </div>
          )}
          {windowWidth > 600 && (
            <div
              className={
                activeTab === 4 ? "active second-grid-list" : "second-grid-list"
              }
              onClick={() => handleTabClick(4)}
            >
              <div className={"second-grid-item"} />
              <div className={"second-grid-item"} />
              <div className={"second-grid-item"} />
              <div className={"second-grid-item"} />
            </div>
          )}
        </div>
        <div className={"featured-cont"}>
          <select className={"feture-inp"}>
            <option>Featured</option>
            <option>Best Selling</option>
          </select>
        </div>
      </div>
      {filter && (
        <div className={"filter-container-vertical"}>
          <div className={"sidebar-collect1"}>
            <div className={"collect-title"}>By Title</div>
            <input
              className={"search-for-product"}
              placeholder={"Search for Product title"}
            />
            <button className={"filter-btn"}>FILTER</button>
          </div>
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>By Price</div>
            <div style={{ width: "70%", marginRight: "40px" }}>
              <Slider
                min={0}
                max={100}
                value={value}
                onChange={handleSliderChange}
              />
            </div>
            <button className={"filter-btn"}>FILTER</button>
          </div>
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>By Size</div>
            <div className={"checkboxes-container"}>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Large
                </label>
                <span className={"ceckbox-quant"}>(130)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Medium
                </label>
                <span className={"ceckbox-quant"}>(130)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Small
                </label>
                <span className={"ceckbox-quant"}>(130)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Unstitched
                </label>
                <span className={"ceckbox-quant"}>(1)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; X-Large
                </label>
                <span className={"ceckbox-quant"}>(129)</span>
              </div>
            </div>
            <button className={"filter-btn"}>FILTER</button>
          </div>
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>By Color</div>
            <div className={"checkboxes-container"}>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Aqua
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Black
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Black Gold
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Blue
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Blush Pink
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Cameo
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Chocolate Brown
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
            </div>
            <br />
            <button className={"filter-btn"}>FILTER</button>
          </div>
        </div>
      )}
      <div className={"content-containeer"}>
        <div className={"collction-sidebar"}>
          <div className={"sidebar-collect1"}>
            <div className={"collect-title"}>By Title</div>
            <input
              className={"search-for-product"}
              placeholder={"Search for Product title"}
            />
            <button className={"filter-btn"}>FILTER</button>
          </div>
          <br />
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>By Price</div>
            <div style={{ width: "70%", marginRight: "40px" }}>
              <Slider
                min={0}
                max={100}
                value={value}
                onChange={handleSliderChange}
              />
            </div>
            <button className={"filter-btn"}>FILTER</button>
          </div>

          <br />
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>By Size</div>
            <div className={"checkboxes-container"}>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Large
                </label>
                <span className={"ceckbox-quant"}>(130)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Medium
                </label>
                <span className={"ceckbox-quant"}>(130)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Small
                </label>
                <span className={"ceckbox-quant"}>(130)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; Unstitched
                </label>
                <span className={"ceckbox-quant"}>(1)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"checkbox"} className={"checkbox"} />
                  &nbsp; X-Large
                </label>
                <span className={"ceckbox-quant"}>(129)</span>
              </div>
            </div>
            <button className={"filter-btn"}>FILTER</button>
          </div>
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>By Color</div>
            <div className={"checkboxes-container"}>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Aqua
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Black
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Black Gold
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Blue
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Blush Pink
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Cameo
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; Chocolate Brown
                </label>
                <span className={"ceckbox-quant"}>(2)</span>
              </div>
            </div>
            <br />
            <button className={"filter-btn"}>FILTER</button>
          </div>
          <div className={"sidebar-collect1"}>
            <div className={"collect-price"}>Product status</div>
            <div className={"checkboxes-container"}>
              <div className={"checkbox-box"}>
                <label>
                  <input type={"radio"} className={"checkbox-radio"} />
                  &nbsp; In stock
                </label>
              </div>
            </div>
            <br />
            <button className={"filter-btn"}>FILTER</button>
          </div>
        </div>
        <div className="all-collection-content">
          <div className="tab-content">
            <div className={activeTab === 1 ? "tab-panel active" : "tab-panel"}>
              <div className={"grid-first-tab"}>
                  {products.slice(0, showCount).map((item, index) => (
                    <FirstTabCard
                    description={item.description}
                    number={item.name}
                    discount={item.discount}
                    to_price={item.purchase_price}
                    from_price={item.unit_price}
                    images={item.images}
                    sizes={item.sizes}
                    product_id={item.id}
                    />
                  ))}


                {/*<FirstTabCard*/}
                {/*  description={*/}
                {/*    "READY TO WEAR Whats Included: Embroidered Shirt (Cotton (100% Pure) Sleeves Attached Trousers (Elasticized waistband) Dupatta Fabric Details: Shirt: (Cotton (100% Pure) Sleeves: Same as shirt Dupatta: Crinkle Chiffon Trousers: (Cotton (100%..."*/}
                {/*  }*/}
                {/*  number={"23"}*/}
                {/*  discount={"20"}*/}
                {/*  to_price={"$30.9"}*/}
                {/*  from_price={"$230"}*/}
                {/*  images={images}*/}
                {/*/>*/}
              </div>
            </div>
            <div className={activeTab === 2 ? "tab-panel active" : "tab-panel"}>
              <div className={"grid-two-tab"}>
                {products.slice(0, showCount).map((item, index) => (
                  <SecondTabCard
                  title={item.name}
                  product_id={item.id}
                  discount={item.discount}
                  discounted_price={item.unit_price}
                  price={item.purchase_price}
                  images={item.images}
                  sizes={item.sizes}
                  />
                ))}

              </div>
            </div>
            <div className={activeTab === 3 ? "tab-panel active" : "tab-panel"}>
              <div className={"grid-third-tab"}>
                {products.slice(0, showCount).map((item, index) => (
                  <ThirdTabCard
                  title={item.name}
                  discount={item.discount}
                  product_id={item.id}
                  discounted_price={item.purchase_price}
                  price={item.unit_price}
                  images={item.images}
                  sizes={item.sizes}
                  />
                ))}

              </div>
            </div>
            <div className={activeTab === 4 ? "tab-panel active" : "tab-panel"}>
              {renderItems()}
              <div className="pagination">{renderPaginationButtons()}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default NewArrivals;
