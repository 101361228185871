import "./Instagram.css";
import React from "react";
import Slider from "react-slick";
import InstagramCard from "../InstagramCard/InstagramCard";
import ZoomCard from "../Card/Card";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Instagram = () => {
  const saleProducts = [
    { id: 1, img: "https://via.placeholder.com/300x200", title: "Product 1" },
    { id: 2, img: "https://via.placeholder.com/300x200", title: "Product 2" },
    { id: 3, img: "https://via.placeholder.com/300x200", title: "Product 3" },
    { id: 1, img: "https://via.placeholder.com/300x200", title: "Product 1" },
    { id: 2, img: "https://via.placeholder.com/300x200", title: "Product 2" },
    { id: 3, img: "https://via.placeholder.com/300x200", title: "Product 3" },
    { id: 1, img: "https://via.placeholder.com/300x200", title: "Product 1" },
    { id: 2, img: "https://via.placeholder.com/300x200", title: "Product 2" },
    { id: 3, img: "https://via.placeholder.com/300x200", title: "Product 3" },
    { id: 1, img: "https://via.placeholder.com/300x200", title: "Product 1" },
    { id: 2, img: "https://via.placeholder.com/300x200", title: "Product 2" },
    { id: 3, img: "https://via.placeholder.com/300x200", title: "Product 3" },
    { id: 1, img: "https://via.placeholder.com/300x200", title: "Product 1" },
    { id: 2, img: "https://via.placeholder.com/300x200", title: "Product 2" },
    { id: 3, img: "https://via.placeholder.com/300x200", title: "Product 3" },
    { id: 1, img: "https://via.placeholder.com/300x200", title: "Product 1" },
    { id: 2, img: "https://via.placeholder.com/300x200", title: "Product 2" },
    { id: 3, img: "https://via.placeholder.com/300x200", title: "Product 3" },
  ];

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`instagram-arrows ${className}`}
        style={style}
        onClick={onClick}
      >
        <FaAngleRight />
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={`instagram-arrows ${className}`}
        style={style}
        onClick={onClick}
      >
        <FaAngleLeft />
      </button>
    );
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <h3 className="h-instagram">
        <span>Instagram</span>
      </h3>
      <h7 className="descp">Khudabaksh Creations Official Instagram</h7>
      <br />
      <div className="slider-container">
        <Slider {...sliderSettings}>
          {saleProducts.map((product) => (
            <div key={product.id}>
              <InstagramCard img={product.img} title={product.title} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Instagram;
