import "./InstagramCard.css";
import instagram from "../../assets/images/instagram.webp";
const InstagramCard = ()=>{
    return (
        <>
            <div className="instagram-image-container">
                <img src={instagram} alt="Image"/>
                    <div className="overlay">
                        <i className="fab fa-instagram instagram-icon"/>
                    </div>
            </div>
        </>
    )
}
export default InstagramCard;