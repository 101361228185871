import React, { useState, useEffect } from "react";
import "./TopHeader.css";
import pakistan_flag from "../../assets/images/pakistan_flag.jpeg";
import eur_flag from "../../assets/images/eur_flag.jpeg";
import usd_flag from "../../assets/images/usd_flag.png";
import gbp_flag from "../../assets/images/gbp_flag.png";
import sek_flag from "../../assets/images/sek_flag.png";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import API_BASE_URL from "../../Constants";
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";

const TopHeader = () => {

  const dispatch = useDispatch();
  const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);
  const [shoppingText, setShoppingText] = useState("Worldwide Shopping");
  const [facebookLink, setFacebookLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [pinterestLink, setPinterestLink] = useState('');
  const [selectedFlag, setSelectedFlag] = useState({
    image: pakistan_flag,
    text: "PKR",
  });

  const shoppingTexts = [
    "Worldwide Shopping",
    "Shop Anywhere",
    "Global Marketplace",
  ];

  useEffect(() => {
    dispatch(fetchWebsiteConfigures());
  }, []);

  useEffect(() => {
    if (website_configures) {
      setFacebookLink(website_configures.facebook_link ?? '');
      setInstagramLink(website_configures.instagram_link ?? '');
      setYoutubeLink(website_configures.youtube_link ?? '');
      setPinterestLink(website_configures.pinterest_link ?? '');
    }
  }, [website_configures]);




  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = shoppingTexts.indexOf(shoppingText);
      const nextIndex = (currentIndex + 1) % shoppingTexts.length;
      setShoppingText(shoppingTexts[nextIndex]);
    }, 1000);
    return () => clearInterval(interval);
  }, [shoppingText, shoppingTexts]);

  const handleFlagClick = (image, text) => {
    setSelectedFlag({ image, text });
  };

  return (
    <>
      <div className={"top-header"}>
        <div className={"first-section-top-header"}>
          <a
            href={facebookLink}
            target={"_blank"}
            className={"first-section-icon-container"}
          >
            <i className="fab fa-facebook" />
          </a>
          <a
            href={instagramLink}
            target={"_blank"}
            className={"first-section-icon-container"}
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            href={youtubeLink}
            target={"_blank"}
            className={"first-section-icon-container"}
          >
            <i className="fab fa-youtube" />
          </a>
          <a
            href={pinterestLink}
            target={"_blank"}
            className={"first-section-icon-container"}
          >
            <i className="fas fa-microchip" />
          </a>
        </div>
        <CSSTransition
          in={true}
          appear={true}
          timeout={300}
          classNames="slide-in"
        >
          <div className={"text-center"}>{shoppingText}</div>
        </CSSTransition>
        <div className={"end-section-top-header"}>
          <img src={selectedFlag.image} className={"flag-img"} alt={"Flag"} />
          {selectedFlag.text}
          &nbsp;
          <i className="fas fa-arrow-down" />
          <div className={"flags-container"}>
            <div className="flags-container-content">
              <div
                className={"flag-box"}
                onClick={() => handleFlagClick(pakistan_flag, "PKR")}
              >
                <img
                  src={pakistan_flag}
                  className={"flag-img"}
                  alt={"Pakistan Flag"}
                />
                &nbsp; PKR
              </div>
              <div
                className={"flag-box"}
                onClick={() => handleFlagClick(eur_flag, "EUR")}
              >
                <img src={eur_flag} className={"flag-img"} alt={"EUR Flag"} />
                &nbsp; EUR
              </div>
              <div
                className={"flag-box"}
                onClick={() => handleFlagClick(usd_flag, "USD")}
              >
                <img src={usd_flag} className={"flag-img"} alt={"USD Flag"} />
                &nbsp; USD
              </div>
              <div
                className={"flag-box"}
                onClick={() => handleFlagClick(gbp_flag, "GBP")}
              >
                <img src={gbp_flag} className={"flag-img"} alt={"GBP Flag"} />
                &nbsp; GBP
              </div>
              <div
                className={"flag-box"}
                onClick={() => handleFlagClick(sek_flag, "SEK")}
              >
                <img src={sek_flag} className={"flag-img"} alt={"SEK Flag"} />
                &nbsp; SEK
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
