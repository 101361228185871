import React, { useEffect, useState } from "react";
import TopHeader from "../../components/TopHeader/TopHeader";
import Navbar from "../../components/Navbar/Navbar";
import './ResetPassword.css';
import { useParams, useLocation } from 'react-router-dom';
import Footer from "../../components/Footer/Footer";
import axios from 'axios';
import Swal from 'sweetalert2';
import API_BASE_URL from "../../Constants";

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const location = useLocation();
    const [email, setEmail] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailParam = searchParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}api/password/reset`, {
                token,
                password,
                password_confirmation: confirmPassword,
                email
            });

            Swal.fire({
                title: 'Success!',
                text: 'Your password has been reset successfully.',
                icon: 'success',
                confirmButtonText: 'OK'
            });

            window.close();
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: error.response?.data?.error?.password || 'An error occurred. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <>
            <TopHeader />
            <Navbar />
            <div className={"top-account-section"}>
                RESET ACCOUNT PASSWORD
            </div>
            <div className={"reset-password-container"}>
                <div className={"main-heading"}>Enter a new password</div>
                <div className={"reset-passwoord-input-container"}>
                    <label>Password</label>
                    <input
                        type={"password"}
                        className={"reset-password-input"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className={"reset-passwoord-input-container"}>
                    <label>Confirm Password</label>
                    <input
                        type={"password"}
                        className={"reset-password-input"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                {error && <p className="error">{error}</p>}
                <button className={"reset-btn"} onClick={handleResetPassword}>Reset Password</button>
            </div>
            <Footer />
        </>
    );
};

export default ResetPassword;
