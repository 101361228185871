import {createSlice} from '@reduxjs/toolkit';

const fetchCategoriesSlice = createSlice({
    name: 'fetchCategories',
    initialState: {
        loading: false,
        categories: [],
        error: null,
    },
    reducers: {
        fetchCategoriesRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchCategoriesSuccess: (state, action) => {
            state.loading = false;
            state.categories = action.payload;
        },
        fetchCategoriesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchCategoriesRequest,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
} = fetchCategoriesSlice.actions;

export default fetchCategoriesSlice.reducer;
