import {configureStore} from '@reduxjs/toolkit';
import fetchWebsiteConfiguresReducer from './fetchWebsiteConfigures/fetchWebsiteConfiuresSlice';
import fetchProductsByCategoryReducer from './fetchProductsByCategory/fetchProductsByCategorySlice';
import fetchCategoriesReducer from './fetchCategories/fetchCategoriesSlice';

const store = configureStore({
    reducer: {
        fetchWebsiteConfigures: fetchWebsiteConfiguresReducer,
        fetchProductsByCategory: fetchProductsByCategoryReducer,
        fetchCategories: fetchCategoriesReducer,
    },
});

export default store;