import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import Home from "./pages/Home/Home";
import AllCollection from "./pages/AllCollection/AllCollection";
import Wishlist from "./pages/Wishlist/Wishlist";
import ProductSingle from "./pages/ProductSingle/ProductSingle";
import Cart from "./pages/Cart/Cart";
import {Provider} from 'react-redux';
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import NewArrivals from "./pages/NewArrivals/NewArrivals";
import SummerCollection from "./pages/SummerCollection/SummerCollection";
import BasicCollection from "./pages/BasicCollection/BasicCollection";
import LuxuryPretCollection from "./pages/LuxuryPret/LuxuryPretCollection";
import SaleCollection from "./pages/SaleCollection/SaleCollection";
import Wintercollection from "./pages/WinterCollection/Wintercollection";
import DupattaShawlCollections from "./pages/DupattaShawlCollection/DupattaShawlCollections";
import Account from "./pages/Account/Account";
import Checkout from "./pages/Checkout/Checkout";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import store from "./redux/store";

function App() {
  const renderRoutes = () => {
    return (
        <Provider store={store}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/collection/all" element={<AllCollection />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/product/single/:id" element={<ProductSingle />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/new-arrivals" element={<NewArrivals />} />
        <Route path="/summer-collection" element={<SummerCollection />} />
        <Route path="/basic-collection" element={<BasicCollection />} />
        <Route path="/luxury-collection" element={<LuxuryPretCollection />} />
        <Route path="/sale-collection" element={<SaleCollection />} />
        <Route path="/winter-collection" element={<Wintercollection />} />
        <Route
          path="/dupatta-collection"
          element={<DupattaShawlCollections />}
        />
        <Route path="/account" element={<Account />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
      </Routes>
        </Provider>
    );
  };

  return (
    <div className="App">
      <HashRouter>{renderRoutes()}</HashRouter>
    </div>
  );
}

export default App;
