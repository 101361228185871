import './ProductSingleDetail.css';
import main_img from "../../assets/images/main_img.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";
import ImageSliderComponent from "../ImagesSlidrComponent/ImagesSliderComponent";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

const ProductSingleDetail = ({product}) => {
    const [activeTab, setActiveTab] = useState('description');
    const [activeColor, setActiveColor] = useState('BLACK');
    const [activeSize, setActiveSize] = useState('Small');
    const [scrolled, setScrolled] = useState(false);
    const [activeImage, setActiveImage] = useState(main_img);
    const [quantity, setQuantity] = useState(1);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }
    const handleColorClick = (color) => {
        setActiveColor(color);
    };
    const handleSizeClick = (size) => {
        setActiveSize(size);
    };
    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    }

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(prevQuantity => prevQuantity - 1);
        }
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleImageClick = (image) => {
        setActiveImage(image);
    }
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    };

    const handleAddToCart = () => {
        if (!product) {
            console.error("Product is not defined");
            return;
        }

        const new_product = {
            title: product.name,
            discounted_price: product.purchase_price,
            product_id :product.id,
            price: product.unit_price,
            images :product.images,
            quantity : quantity,
            selectedSize : activeSize,
            discount: "new",
        };

        let cart = JSON.parse(sessionStorage.getItem('cart')) || [];

        const productIndex = cart.findIndex((item) => item.product_id === product.id);

        if (productIndex !== -1) {
            cart[productIndex].quantity += quantity;
        } else {
            cart.push(new_product);
        }

        sessionStorage.setItem('cart', JSON.stringify(cart));
        console.log('Product added/updated in cart:', new_product);

        Swal.fire({
            icon: 'success',
            title: 'Added to Cart',
            text: `${new_product.title} has been added to your cart!`,
            showConfirmButton: false,
            timer: 2000,
        });

    };
    return (
        <>
            <div className={"product-single-detail-container"}>
                <ImageSliderComponent images={product.images}/>
                <div className={"product-single-detail-second"}>
                    <span className={"product-title"}>{product.name}</span>
                    <div className={"pricing-container"}>
                        <div className={"price-box"}>
                            <span className={"line-price"}>{product.purchase_price} </span>
                            <span className={"price"}>{product.unit_price}</span>
                        </div>
                        <span className={"stock"}>In Stock</span>
                    </div>
                    <span className={"shipping-text"}>Shipping calculated at checkout.</span>
                    <div className={"color"}>COLOR:BLACK</div>
                    <div className={"color-box"}>
                        <div  className={`color-section ${activeColor === 'BLACK' ? 'active' : ''}`}
                              onClick={() => handleColorClick('BLACK')}>BLACK</div>
                        <div className={`color-section ${activeColor === 'OFFWHITE' ? 'active' : ''}`}
                             onClick={() => handleColorClick('OFFWHITE')}
                        >OFF White</div>
                    </div>
                    <div className={"size"}>SIZE:SMALL</div>
                    <div className={"size-box"}>
                        {product.tags.map((tag) => (
                            <div className={`size-section ${activeSize === tag ? 'active' : ''}`}
                                 onClick={() => handleSizeClick(tag)}
                            >{tag}</div>
                        ))}



                    </div>
                    <div className={"add-to-cart-container"}>
                        <div className={"quantity-container"}>
                            <span className={"minus-sign"} onClick={decrementQuantity}>-</span>
                            <span>{quantity}</span>
                            <span className={"plus-sign"} onClick={incrementQuantity}>+</span>
                        </div>
                        <button className={"add-to-cart-btn"} onClick={handleAddToCart}>
                            ADD TO CART
                        </button>
                        <div className={"heart-container"}>
                            <i className={"fas fa-heart"} />
                        </div>
                    </div>
                    <Link to={"/checkout"} className={"buy-btn"}>BUY IT NOW</Link>
                    <br />
                    <div className={"whatsapp-div"}>
                        <i className="fab fa-whatsapp" />
                        <div className={"btn-content"}>
                            <div className={"btn-content-top"}>
                                <span className={"helpline"}>Helpline!!</span>
                                <span className={"online"}>Online</span>
                            </div>
                            <div className={"btn-content-bottom"}>
                                Need Help? Chat with us
                            </div>
                        </div>
                    </div>
                    <span className={"sold-text"}><i className={"fas fa-tint"} /> &nbsp;
                        24 sold in last 9 hours</span>
                    <div className={"tabs-container"}>
                        <button className={`tab ${activeTab === 'description' ? 'active' : ''}`} onClick={() => handleTabClick('description')}>Description</button>
                        <button className={`tab ${activeTab === 'size' ? 'active' : ''}`} onClick={() => handleTabClick('size')}>Size Chart</button>
                        <button className={`tab ${activeTab === 'info' ? 'active' : ''}`} onClick={() => handleTabClick('info')}>Additional Information</button>
                    </div>
                    {/* Content for each tab */}
                    {activeTab === 'description' && (
                        <>
                            <h3 className={"tab-heading"}>Product Description</h3>
                            <p className={"tab-content"}>{product.description}</p>
                        </>
                    )}
                    {activeTab === 'size' && (
                        <>
                            <h3 className={"tab-heading"}>General Size Guide</h3>
                            <table className={"size-table"}>
                                <thead>
                                <tr>
                                    <th>SIZE (inch)</th>
                                    <th>S</th>
                                    <th>M</th>
                                    <th>L</th>
                                    <th>XL</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"bold"}>SHOULDER</td>
                                    <td>14.5</td>
                                    <td>15.5</td>
                                    <td>16.5</td>
                                    <td>17</td>
                                </tr>
                                <tr>
                                    <td className={"bold"}>BRUST</td>
                                    <td>14.5</td>
                                    <td>15.5</td>
                                    <td>16.5</td>
                                    <td>17</td>
                                </tr>
                                <tr>
                                    <td className={"bold"}>WAIST</td>
                                    <td>14.5</td>
                                    <td>15.5</td>
                                    <td>16.5</td>
                                    <td>17</td>
                                </tr>
                                <tr>
                                    <td className={"bold"}>HIP</td>
                                    <td>14.5</td>
                                    <td>15.5</td>
                                    <td>16.5</td>
                                    <td>17</td>
                                </tr>
                                <tr>
                                    <td className={"bold"}>DAMAN</td>
                                    <td>14.5</td>
                                    <td>15.5</td>
                                    <td>16.5</td>
                                    <td>17</td>
                                </tr>
                                <tr>
                                    <td className={"bold"}>ARM-HOLE</td>
                                    <td>14.5</td>
                                    <td>15.5</td>
                                    <td>16.5</td>
                                    <td>17</td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                    {activeTab === 'info' && (
                        <>
                            <table className={"info-table"}>
                                <tbody>
                                <tr>
                                    <td className={"bold"}>Color</td>
                                    <td>Black, Off White</td>
                                </tr>
                                <tr>
                                    <td className={"bold"}>Size</td>
                                    <td>Small, Medium, Large, X-Large</td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>

            {windowWidth <= 600 && (
                <div className={`bottom-b-mobile ${scrolled ? ' ' : ''}`}>
                    <div className={"bottom-b-m"}>Redish Orange /Medium</div>
                    <div  className={"bottom-b-m-box"}>
                        <div className={"quantity-box"}>
                            <span onClick={decrementQuantity}>-</span>
                            {quantity}
                            <span onClick={incrementQuantity}>+</span>
                        </div>
                        <div className={"add-cart-btn-mobl"}>Add To Cart</div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProductSingleDetail;
