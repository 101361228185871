import axios from 'axios';
import API_BASE_URL from "../../Constants";
import {
    fetchCategoriesRequest,
    fetchCategoriesFailure,
    fetchCategoriesSuccess,
} from './fetchCategoriesSlice';

export const fetchCategories = () => async (dispatch) => {
    dispatch(fetchCategoriesRequest());

    try {
        const apiUrl = `${API_BASE_URL}api/categories`;

        const headers = {
            'client_id': 'hXBgPPlvpwkf4Y7NUclkw93U8VxyniigR6mkDaTM',
            'client_secret': 'lV0YFr0iv2BxooHzmoyA60mjMXwBsEGuDznPqiWngdzykXcVaR6T5EtDfp5J',
        };

        const response = await axios.get(apiUrl, { headers });
        console.log(response);
        dispatch(fetchCategoriesSuccess(response.data.data));
    } catch (error) {
        dispatch(fetchCategoriesFailure(error.message));
    }
};
