import './Footer.css';
import React, {useEffect, useState} from "react";
import MasterCard from "../../assets/images/masster_card.png";
import visa from "../../assets/images/visa.png";
import {useDispatch, useSelector} from "react-redux";
import {fetchWebsiteConfigures} from "../../redux/fetchWebsiteConfigures/fetchWebsiteConfiguresActions";
const Footer = ()=>{

    const dispatch = useDispatch();
    const { website_configures } = useSelector((state) => state.fetchWebsiteConfigures);
    const [contact,setContact] = useState('');
    const [email,setEmail] = useState('');

    useEffect(() => {
        dispatch(fetchWebsiteConfigures());
    }, []);

    useEffect(() => {
        if (website_configures) {
            setContact(website_configures.phone ?? '');
            setEmail(website_configures.email ?? '');
        }
    }, [website_configures]);


    return(
        <>
            <div className={"footer"}>
                <div className={"footer-content"}>
                    <div className={"contact-info"}>CONTACT INFO</div>
                    <div className={"info-container"}>
                        <i className="fas fa-envelope"/>
                        <span> {email}</span>
                    </div>
                    <div className={"info-container"}>
                        <i className="fas fa-phone"/>
                        <span>{contact}</span>
                    </div>
                    <div className={"first-section-top-footer"}>
                        <div className={"first-icon-container"}>
                            <i className="fab fa-facebook" />
                        </div>
                        <div className={"first-icon-container"}>
                            <i className="fab fa-instagram" />
                        </div>
                        <div className={"first-icon-container"}>
                            <i className="fab fa-youtube" />
                        </div>
                        <div className={"first-icon-container"}>
                            <i className="fas fa-microchip"/>
                        </div>
                    </div>
                    <div className={"whatsapp-btn"}><i className="fab fa-whatsapp"/>
                        Whatsapp</div>
                    <div className={"accept-text"}>WE ACCEPT</div>
                    <div className={"pament-container"}>
                        <img className={"payment-method"} src={visa} alt={"image not found"} />
                        <img className={"payment-method"} src={MasterCard} alt={"image not found"} />
                    </div>
                </div>
            </div>
            <div className={"footer-bottom"}>
                <div>Copyright © [2022] <span className={"footer-bottom-link"}>Khudabakshcreations</span> All Rights Reserved. Powered by <span className={"footer-bottom-link"}>Khuda Baksh</span></div>
                <div className={"footer-tab"}>
                    <span className={"footer-item"}>Search</span>
                    <span className={"footer-item"}>Return & Exchange Policy</span>
                    <span className={"footer-item"}>Contact us</span>
                    <span className={"footer-item"}>Our Retailers</span>
                    <span className={"footer-item"}>About us </span>
                    <span className={"footer-item"}>Terms of Service</span>
                    <span className={"footer-item"}>Faq </span>
                </div>
            </div>
        </>
    )
}
export default Footer;