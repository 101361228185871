import React, { useEffect } from 'react';
import CollectionCard from "../CollectionCard/CollectionCard";
import './AllCollection.css';
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../redux/fetchCategories/fetchCategoriesActions";
import API_BASE_URL from "../../Constants";

const AllCollection = () => {
    const dispatch = useDispatch();

    const { categories, loading, error } = useSelector((state) => state.fetchCategories);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <div className={"all-collection"}>
                {categories.map((category, index) => (
                    <CollectionCard
                        key={index}
                        img={`${API_BASE_URL}${category.banner}`}
                        title={category.name}
                        product={category.product_count ?? ""}
                    />
                ))}
            </div>
        </>
    );
};

export default AllCollection;
