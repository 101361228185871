import './ProductTopBar.css';
const ProductTopBar = ({product_name})=>{
    return (
        <>
            <div className={"product-top-bar"}>
                <div className={"product-top-first"}>
                    <span>Khudabaksh</span>
                    <i className="fas fa-chevron-right"/>
                    <span>{product_name}</span>
                </div>
                <div className={"product-top-end"}>
                    <i className="fas fa-tools"/>
                    <i className="fas fa-chevron-right"/>
                </div>
            </div>
        </>
    )
}
export default ProductTopBar;